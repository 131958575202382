import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Paper, CircularProgress, Typography } from '@mui/material';
import { Marker } from '@react-google-maps/api';
import { NotificationContext } from '../../../../NotificationContext';
import { getDefaultLatitude, getDefaultLongitude, getUserRegion } from '../../../../../utils';
import { useGetTrailerEvents, useGetPlaybackDetails } from './RouteSessionPlayback.queries';
import listGeofences from '../../../../repository/geofences/listGeofences';
import getRoutePoints from '../../../../repository/geo/getRoutePoints';
import { iconUrlMap, routeDetailsIconSize } from '../../shapes/MapShapes.helpers';
import Map from '../Map';
import { PlaybackMenu } from '.';

const appSx = (theme) => ({
    title: {
        fontSize: theme.typography.h5,
        marginY: theme.spacing(2),
    },
    paper: { display: 'flex', flexDirection: 'column', width: '100%', padding: theme.spacing(2), borderRadius: '8px' },
});

const RouteSessionPlayback = ({ routeSessionId, isProcessedFluids = false, customCenter = null }) => {
    const { showSnackbar } = useContext(NotificationContext);
    const { data: trailerEvents, isLoading: trailerEventsLoading } = useGetTrailerEvents({ routeSessionId });
    const { data: playbackDetails, isLoading: playbackDetailsLoading } = useGetPlaybackDetails({ routeSessionId, isProcessedFluids });
    const region = getUserRegion();
    const [zoom, setZoom] = useState(12);
    const [loading, setLoading] = useState(true);
    const [routePoints, setRoutePoints] = useState([]);
    const [geofences, setGeofences] = useState([]);
    const [producerIds, setProducerIds] = useState([]);
    const [processorIds, setProcessorIds] = useState([]);
    const [pickupShapes, setPickupShapes] = useState([]);
    const [dropoffShapes, setDropoffShapes] = useState([]);
    const routeCoordinates = useMemo(() => routePoints.map((point) => ({ lat: point.location.coordinates[1], lng: point.location.coordinates[0] })), [routePoints]);
    const [playbackCoordinates, setPlaybackCoordinates] = useState();

    const fetchAndSetRoutePoints = async (id) => {
        const radarPoints = await getRoutePoints(id);
        if (radarPoints.length) {
            radarPoints[0].event_type = 'StartedRoute';
            radarPoints[radarPoints.length - 1].event_type = 'EndedRoute';
        }
        setRoutePoints(radarPoints);
    };

    const fetchAndSetGeofence = async () => {
        const { pickups, dropoffs } = playbackDetails;
        const producerIdsMap = pickups?.map((pickup) => pickup?.producer_id?._id || pickup?.origin_processor_id?._id);
        const pickupShapesMap = pickups?.map((pickup) => {
            return {
                _id: pickup._id,
                name: pickup?.producer_id?.name || pickup?.origin_processor_id?.name || 'Unknown',
                type: 'rawMilkPickup',
                center: {
                    lng: Number(pickup?.longitude),
                    lat: Number(pickup?.latitude),
                },
                date: pickup?.created_at,
                volume: pickup?.volume,
            };
        });
        setPickupShapes(pickupShapesMap);
        setProducerIds(producerIdsMap);

        const processorIdsMap = dropoffs?.map((dropoff) => dropoff?.processor_id?._id || dropoff?.destination_processor_id?._id);
        const dropoffShapesMap = dropoffs?.map((dropoff) => {
            return {
                _id: dropoff?._id,
                name: dropoff?.processor_id?.name || dropoff?.destination_processor_id?.name || 'Unknown',
                type: 'rawMilkDropoff',
                center: {
                    lng: Number(dropoff?.longitude),
                    lat: Number(dropoff?.latitude),
                },
                date: dropoff?.created_at,
                volume: dropoff?.metered,
            };
        });

        setDropoffShapes(dropoffShapesMap);
        setProcessorIds(processorIdsMap);

        try {
            const geofencesResp = await listGeofences({ ids: [...producerIdsMap, ...processorIdsMap] });
            setGeofences(geofencesResp);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            setGeofences([]);
            return showSnackbar({ severity: 'error', message: 'There was a problem retrieving map data. If the problem persists please contact support.' });
        }
    };

    useEffect(() => {
        const fetchAndSetAllPoints = async (id) => {
            setLoading(true);
            await fetchAndSetRoutePoints(id);
            await fetchAndSetGeofence();
            setLoading(false);
        };

        if (!playbackDetailsLoading) {
            fetchAndSetAllPoints(routeSessionId);
        }
    }, [routeSessionId, playbackDetails]);

    const center = useMemo(() => {
        if (playbackCoordinates !== undefined) return { lat: playbackCoordinates.lat, lng: playbackCoordinates.lng };
        if (customCenter !== null) return customCenter;
        if (routeCoordinates.length > 0) {
            return routeCoordinates[Math.round((routeCoordinates.length - 1) / 2)];
        }
        return { lat: getDefaultLatitude(region), lng: getDefaultLongitude(region) };
    }, [playbackCoordinates, routeCoordinates]);

    const handleViewChange = (props) => {
        setZoom(props.zoom);
    };

    return (
        <>
            {loading || trailerEventsLoading || playbackDetailsLoading ? (
                <CircularProgress className="mx-auto my-48" />
            ) : (
                <>
                    <Paper sx={(theme) => appSx(theme).paper}>
                        <Typography sx={(theme) => appSx(theme).title}>Route Details</Typography>
                        <Map
                            data={[{ _id: Date.now().toString(), type: 'polyline', coordinates: routeCoordinates }, ...geofences, ...pickupShapes, ...dropoffShapes]}
                            center={center}
                            zoom={zoom}
                            handleViewChange={handleViewChange}
                            mapStyle={{
                                width: '100%',
                                height: '550px',
                            }}
                            producerIds={producerIds}
                            processorIds={processorIds}
                            playbackCoordinates={playbackCoordinates}
                            trailerEvents={trailerEvents}
                            mapTypeId="satellite"
                            polylineStroke={6}
                        >
                            {playbackCoordinates && (
                                <Marker
                                    position={playbackCoordinates}
                                    icon={{
                                        url: iconUrlMap.driver,
                                        scaledSize: routeDetailsIconSize,
                                    }}
                                />
                            )}
                        </Map>
                        {routePoints.length > 2 && <PlaybackMenu route={routePoints} setPlaybackCoordinates={setPlaybackCoordinates} />}
                    </Paper>
                </>
            )}
        </>
    );
};

export default RouteSessionPlayback;
