const commodities = {
    buttermilk: 'Buttermilk',
    buttermilk_grade_a: 'Buttermilk Grade A',
    buttermilk_grade_b: 'Buttermilk Grade B',
    condensed: 'Condensed',
    condensed_buttermilk: 'Condensed Buttermilk',
    condensed_skim: 'Condensed Skim',
    cream: 'Cream',
    cream_grade_a: 'Cream Grade A',
    cream_manufacturing: 'Cream Manufacturing',
    hi_heat_condensed_skim: 'Hi Heat Condensed Skim',
    fitpro_uf_blend: 'FitPro UF Blend',
    fluid_buttermilk: 'Fluid Buttermilk',
    mbst_free_hi_heat_condensed_1251: 'MBST Free Hi Heat Condensed 1251',
    med_heat_condensed_skim: 'Med Heat Condensed Skim',
    milk_transfer: 'Milk Transfer',
    permeate: 'Permeate',
    premix: 'Premix',
    premix_condensed: 'Premix Condensed',
    premix_milk_blend: 'Premix Milk Blend',
    rbst_free_condensed: 'rBST Free Condensed',
    rbst_free_cream: 'rBST Free Cream',
    rbst_free_milk: 'rBST Free Milk',
    rbst_free_skim: 'rBST Free Skim',
    rbst_free_whole_milk_condensed: 'rBST Free Whole Milk Condensed',
    ro_buttermilk: 'RO Buttermilk',
    skim: 'Skim',
    tropicale_blend: 'Tropicale Blend',
    uf_milk: 'UF Milk',
    uf_skim: 'UF Skim',
    uf_skim_rbst_free: 'UF Skim rBST Free',
    whole_milk_condensed: 'Whole Milk Condensed',
    low_heat_whole_milk_condensed: 'Low Heat Whole Milk Condensed',
    other: 'Other',
    repair: 'Repair',
    tanker_move: 'Tanker Move',
    wash: 'Wash',
    low_spore_condensed: 'Low Spore Condensed',
    white_satin: 'White Satin',
    animal_feed: 'Animal Feed',
    pusher: 'Pusher',
    permeate_feed: 'Permeate Feed',
    condensed_whole_milk_10_2_06: 'Condensed Whole Milk 10.2.06',
    concentrated_skim_milk_10_2_51: 'Concentrated Skim Milk 10.2.51',
    concentrated_whole_milk_10_2_52: 'Concentrated Whole Milk 10.2.52',
    buttermilk_dump: 'Butter Milk Dump',
    a2_raw_milk: 'A2 Raw Milk',
    a2_uf_skim_milk: 'A2 UF Skim Milk',
    a2_cream: 'A2 Cream',
    a2_ro_permeate: 'A2 RO Permeate',
    a2_uf_mpc85: 'A2 UF MPC85',
};

const disabled = [commodities.buttermilk, commodities.cream, commodities.ro_buttermilk];

const config = {
    commodities,
    disabled,
};

export default config;
