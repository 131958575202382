import { useEffect } from 'react';
import useSSE from '../../../../hooks/useServerSentEvents';
import useBatching from '../../../../hooks/useBatching';

const useSupplyItemsSSE = () => {
    const { data: supplyItemUpdate, status } = useSSE('schedule-supply-items');
    const { batchedItems: batchedNewSupplyItems, addItems: addBatchedNewSupplyItem } = useBatching(2500);
    const { batchedItems: batchedUpdateSupplyItems, addItems: addBatchedUpdateSupplyItems } = useBatching(500);
    const { batchedItems: batchedUpdateManySupplyItems, addItems: addBatchedUpdateManySupplyItems } = useBatching(100);

    useEffect(() => {
        if (supplyItemUpdate?._ids) {
            if (supplyItemUpdate.method === 'save') {
                // Copying schedule items will trigger a save event for each item
                // Batching the updates will prevent excessive refetches
                addBatchedNewSupplyItem(supplyItemUpdate?._ids);
            } else if (supplyItemUpdate.method === 'updateMany') {
                addBatchedUpdateManySupplyItems(supplyItemUpdate?._ids);
            } else {
                addBatchedUpdateSupplyItems(supplyItemUpdate?._ids);
            }
        }
    }, [supplyItemUpdate]);

    return { batchedNewSupplyItems, batchedUpdateManySupplyItems, batchedUpdateSupplyItems, status };
};

export default useSupplyItemsSSE;
