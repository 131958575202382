import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from 'app/store/actions';
import moment from 'moment-timezone';
import { FuseLayouts } from '@fuse';
import _ from '@lodash';
import AppContext from 'app/AppContext';
import { MMSplashScreen } from 'app/custom-components';
import { getTimeZone, getDateSelectorStartTime } from '../../../utils';
import { MONTHLY_REPORT_REGIONS } from '../../../app/constants/region-utils';
import { getNavigationConfig } from '../../../app/main/apps/ConfiguredApps/navigation';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        '& code:not([class*="language-"])': {
            color: theme.palette.secondary.dark,
            backgroundColor: '#f5f5f5',
            padding: '2px 3px',
            borderRadius: 2,
            lineHeight: 1.7,
        },
        '& table.simple tbody tr td': {
            borderColor: theme.palette.divider,
        },
        '& table.simple thead tr th': {
            borderColor: theme.palette.divider,
        },
        '& a:not([role=button])': {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& [class^="border-"]': {
            borderColor: theme.palette.divider,
        },
        '& [class*="border-"]': {
            borderColor: theme.palette.divider,
        },
    },
});

class FuseLayout extends Component {
    constructor(props, context) {
        super(props);
        this.appContext = context;
        this.routeSettingsCheck();

        this.navigation = getNavigationConfig();
    }

    state = {
        setSharedUnreadCount: true,
        setIntercomUnreadCount: true,
        setQuotaPendingCount: true,
        setMonthlyReportsCount: true,
        numOfUnreadNotifications: 0,
    };

    componentDidMount() {
        window.Intercom('onShow', function () {});

        window.Intercom('onUnreadCountChange', function (unreadCount) {
            sessionStorage.setItem('intercom_unread', unreadCount);
        });

        this.getNumberOfUnreadNotifications();

        if (this.props.userRole !== 'guest') {
            if (MONTHLY_REPORT_REGIONS.includes(this.userRegion) && this.props.userRole === 'producer') {
                this.setState({ setMonthlyReportsCount: false });
                this.props.getMonthlyReportsUnreadCount().then((result) => {
                    if (!!result && !!result.payload.success) {
                        this.setMonthlyReportsUnreadCount(result.payload.unreadCount);
                    }
                });
            }

            moment.tz.setDefault(getTimeZone(this.props.userRegion));
            this.props.setStartDate(getDateSelectorStartTime(this.props.userRegion) ?? '2022-01-01');

            this.props.getSharedUnreadCount().then((result) => {
                if (!!result && !!result.payload.success) {
                    this.setSharedFilesUnreadCount(result.payload.unreadCount);
                }
            });

            // this.props.getPendingLeaseCount().then(result => {
            //     if (!!result && !!result.payload.success) {
            //         this.setPendingQuotaLeaseCount(result.payload.pendingCount);
            //     }
            // });
        } else {
            this.setState({ setSharedUnreadCount: true, setQuotaPendingCount: true });
        }

        // Temp remove support page/badge
        // this.setSupportBadge(parseInt(sessionStorage.getItem('intercom_unread')) || 0);
    }

    getNumberOfUnreadNotifications = () =>
        new Promise(() => {
            const { userRole } = this.props;
            if (userRole !== 'guest') {
                this.props.getNumOfUnreadNotifications().then((response) => this.setState({ numOfUnreadNotifications: response.payload.count }));
            }
        });

    setMonthlyReportsUnreadCount = (value) => {
        this.navigation
            .find((e) => {
                return e.id === 'pages';
            })
            ['children'].find((e) => {
                return e.id === 'monthly-reports';
            })['badge']['title'] = value;

        this.setState({ setMonthlyReportsCount: true });
    };

    setPendingQuotaLeaseCount = (value) => {
        this.navigation
            .find((e) => {
                return e.id === 'pages';
            })
            ['children'].find((e) => {
                return e.id === 'quota-lease';
            })['badge']['title'] = value;

        this.setState({ setQuotaPendingCount: true });
    };

    setSharedFilesUnreadCount = (value) => {
        this.navigation
            .find((e) => {
                return e.id === 'pages';
            })
            ['children'].find((e) => {
                return e.id === 'file-share';
            })['badge']['title'] = value;
        this.navigation
            .find((e) => {
                return e.id === 'pages';
            })
            ['children'].find((e) => {
                return e.id === 'file-share';
            })
            ['children'].find((e) => {
                return e.id === 'file-share-received';
            })['badge']['title'] = value;

        this.setState({ setSharedUnreadCount: true });
    };

    setSupportBadge = (value) => {
        this.navigation
            .find((e) => {
                return e.id === 'status';
            })
            ['children'].find((e) => {
                return e.id === 'support';
            })['badge']['title'] = value;

        this.setState({ setIntercomUnreadCount: true });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
            this.routeSettingsCheck();
        }
    }

    routeSettingsCheck = () => {
        const { routes } = this.appContext;

        const matched = matchRoutes(routes, this.props.location.pathname)[0];

        if (matched && matched.route.settings) {
            const routeSettings = _.merge({}, this.props.defaultSettings, matched.route.settings);
            if (!_.isEqual(this.props.settings, routeSettings)) {
                this.props.setSettings(_.merge({}, routeSettings));
            }
        } else {
            if (!_.isEqual(this.props.settings, this.props.defaultSettings)) {
                this.props.resetSettings();
            }
        }
    };

    render() {
        const { settings, classes } = this.props;
        const { setSharedUnreadCount, setIntercomUnreadCount, setQuotaPendingCount, setMonthlyReportsCount } = this.state;
        // console.warn('FuseLayout:: rendered');

        const Layout = FuseLayouts[settings.layout.style];

        if (!setSharedUnreadCount || !setIntercomUnreadCount || !setQuotaPendingCount || !setMonthlyReportsCount) {
            return <MMSplashScreen />;
        }

        return <Layout className={classes.root} {...this.props} {...this.state} getNumOfUnreadNotifications={() => this.getNumberOfUnreadNotifications()} />;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSettings: Actions.setSettings,
            resetSettings: Actions.resetSettings,
            getSharedUnreadCount: Actions.getSharedUnreadCount,
            getPendingLeaseCount: Actions.getPendingLeaseCount,
            getMonthlyReportsUnreadCount: Actions.getMonthlyReportsUnreadCount,
            getNumOfUnreadNotifications: Actions.getNumOfUnreadNotifications,
            setStartDate: Actions.setStartDate,
            setDate: Actions.setDate,
        },
        dispatch
    );
}

function mapStateToProps({ fuse, persisted }) {
    return {
        defaultSettings: fuse.settings.defaults,
        settings: fuse.settings.current,
        navigation: fuse.navigation,
        userRole: persisted.auth.user.role,
        userRegion: persisted.auth.user.data.region,
    };
}

FuseLayout.contextType = AppContext;

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseLayout)));
