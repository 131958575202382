import {
    Dashboard,
    ListAdmins,
    ListProducers,
    ListProcessors,
    ListTrailers,
    ListHaulingCompanies,
    ListDrivers,
    ManageProcessorTotals,
    ListRouteSessions,
    ListDropoffs,
    ListPickups,
    ListLabReports,
    ListSilos,
    ListCIPWash,
    ListManifests,
    ListUserGroups,
    GetMilkScheduleDetails,
    ListSharedFiles,
    ViewDriverLocations,
    UploadDownload,
    ListReceivers,
    GetFluidScheduleDetails,
    ManageAssignees,
    ManageMilkPrices,
    ManageProducerPayReports,
    ListPickupsAndLabs,
    ListMonthlyReports,
    ManageClosePeriod,
    ManageEquity,
    ManagePoolingDecisions,
    ManageProducerTouchBases,
    ManageMerchandise,
    ManageProducerAssignments,
    ManageHaulerAssignments,
    ManageHaulingCharges,
    ManageHaulingContracts,
    ManageHaulerRates,
    ManageVendorPayReports,
    ManagePaymentStatus,
    ManageHaulerPayReports,
    ListSubAdmins,
    ListSubProducers,
    ListSubProcessors,
    ListSubHauling,
    ManageSplitProducers,
    ViewHaulerPayReport,
    ViewAccountingReports,
    ListScheduleRoutes,
    ProducerHaulingContractsAndCharges,
    ListBaseTransfers,
    ManageProcessedFluidsPickups,
    ManageDeliveryReports,
    ViewDocumentation,
    AccessPlantManagement,
    AccessHaulerManagement,
    AccessProducerManagement,
    ViewReceivingDropoffs,
    ManageBankingInfo,
} from './permissions';
import LABELS from './constants';

const region = 'PRAIRIE';

const SubAdminManagement = {
    label: LABELS.Management,
    permissions: [
        ListAdmins,
        ListProducers,
        ListDrivers,
        ListProcessors(region),
        ListHaulingCompanies(region),
        ListTrailers,
        ListSubAdmins,
        // force wrap
    ],
};

const SubAdminPooling = {
    label: LABELS.Pooling,
    permissions: [ManagePoolingDecisions, ManageProcessorTotals, ManageProducerTouchBases],
};

const SubAdminGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        ListUserGroups,
        ViewDriverLocations,
        ListScheduleRoutes,
        ManageClosePeriod,
        ListPickups(),
        ListDropoffs(),
        ListCIPWash,
        ListRouteSessions(region),
        ManageDeliveryReports,
        ListLabReports(),
        ManageEquity,
        UploadDownload,
        ListManifests,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        ListSharedFiles,
        ManageClosePeriod,
        ViewDocumentation,
        AccessPlantManagement,
        AccessHaulerManagement,
        AccessProducerManagement,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubAdminAccounting = {
    label: LABELS.Accounting,
    permissions: [
        ViewAccountingReports,
        ManageMerchandise(region),
        ManageProducerAssignments,
        ManageHaulerAssignments,
        ManageAssignees,
        ManageMilkPrices,
        ListBaseTransfers(region),
        ManageSplitProducers,
        ManageHaulingCharges(),
        ManageHaulingContracts(),
        ManageHaulerRates,
        ManageProducerPayReports,
        ManageHaulerPayReports,
        ManageVendorPayReports,
        ManagePaymentStatus,
        ManageBankingInfo,
        // force wrap
    ],
};

const SubProducerManagement = {
    label: LABELS.Management,
    permissions: [ListSubProducers],
};

const SubProducerGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListPickups('View', ''),
        ListPickupsAndLabs,
        ListLabReports('View', ''),
        UploadDownload,
        ListSharedFiles,
        AccessProducerManagement,
        // force wrap
    ],
};

const SubProducerAccounting = {
    label: LABELS.Accounting,
    permissions: [
        ProducerHaulingContractsAndCharges,
        ListMonthlyReports(region),
        // force-wrap
    ],
};

const SubProcessorManagement = {
    label: LABELS.Management,
    permissions: [ListReceivers, ListSubProcessors(region)],
};

const SubProcessorGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        ListDropoffs('View', ''),
        ManageProcessedFluidsPickups,
        ListRouteSessions(region, 'View', ''),
        UploadDownload,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        ListSharedFiles,
        AccessPlantManagement,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubHaulingManagement = {
    label: LABELS.Management,
    permissions: [ListDrivers, ListTrailers, ListSubHauling()],
};

const SubHaulingGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ViewDriverLocations,
        ListPickups(),
        ListDropoffs(),
        ListCIPWash,
        GetMilkScheduleDetails('View', ''),
        GetFluidScheduleDetails,
        UploadDownload,
        ListSharedFiles,
        AccessHaulerManagement,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubHaulingAccounting = {
    label: LABELS.Accounting,
    permissions: [ProducerHaulingContractsAndCharges, ViewHaulerPayReport],
};

const SubAdmin = [SubAdminManagement, SubAdminGeneral, SubAdminAccounting, SubAdminPooling];
const SubProducer = [SubProducerManagement, SubProducerGeneral, SubProducerAccounting];
const SubProcessor = [SubProcessorManagement, SubProcessorGeneral];
const SubHauling = [SubHaulingManagement, SubHaulingGeneral, SubHaulingAccounting];

const REQUIRED_PERMISSIONS = [
    ListSilos,
    ListUserGroups,
    ListManifests,
    GetMilkScheduleDetails(),
    GetFluidScheduleDetails,
    ListTrailers,
    // force-wrap
];

const PRAIRIE = {
    Admin: { permissions: SubAdmin, required: REQUIRED_PERMISSIONS.Admin },
    'Sub-Admin': { permissions: SubAdmin },
    'Sub-Producer': { permissions: SubProducer },
    Accountant: { permissions: SubProducer },
    'Feed-Provider': { permissions: SubProducer },
    Nutritionist: { permissions: SubProducer },
    'Co-Owner': { permissions: SubProducer },
    'Stock-Holder': { permissions: SubProducer },
    'Sub-Processor': { permissions: SubProcessor },
    'Sub-Hauling': { permissions: SubHauling },
};

export default PRAIRIE;
