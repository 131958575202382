import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, DialogContentText } from '@material-ui/core';
import { Button as ModernButton } from '@mui/material';
import * as FuseActions from 'app/store/actions';
import { getFileURL } from '../../repository';

function PaperComponent(props) {
    return <Paper {...props} />;
}

const noSlip = 'no_slip.png';

class SlipDialog extends React.Component {
    state = {
        slipOpen: false,
        meteredOpen: false,
        currentWashOpen: false,
        previousWashOpen: false,
        slipAddress: '',
        meteredAddress: '',
        currentWashSlipAddress: '',
        previousWashSlipAddress: '',
        fullSlipURL: '',
        fullMeteredURL: '',
        fullCurrentWashSlipURL: '',
        fullPreviousWashSlipURL: '',
    };

    componentDidMount() {
        const { data, type } = this.props;
        const { slip, metered_slip, current_wash_slip, previous_wash_slip, ticket_s3_location } = data;

        switch (type) {
            case 'Pickup':
            case 'Route Session Pickup': {
                this.setState({ slipAddress: data.slip || noSlip });
                this.setSlipURLState(slip, 'fullSlipURL');

                break;
            }

            case 'Dropoff':
            case 'Route Session Dropoff': {
                this.setState({
                    slipAddress: slip || noSlip,
                    meteredAddress: metered_slip || noSlip,
                    currentWashSlipAddress: current_wash_slip || noSlip,
                    previousWashSlipAddress: previous_wash_slip || noSlip,
                });

                this.setSlipURLState(slip, 'fullSlipURL');
                this.setSlipURLState(metered_slip, 'fullMeteredURL');
                this.setSlipURLState(current_wash_slip, 'fullCurrentWashSlipURL');
                this.setSlipURLState(previous_wash_slip, 'fullPreviousWashSlipURL');

                break;
            }

            case 'DFNSDropoff': {
                this.setState({ meteredAddress: metered_slip || noSlip });
                this.setSlipURLState(metered_slip, 'fullMeteredURL');

                break;
            }
            case 'Wash': {
                this.setState({ slipAddress: ticket_s3_location || noSlip });
                this.setSlipURLState(ticket_s3_location, 'fullSlipURL');

                break;
            }
            default:
                break;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { slipAddress } = this.state;
        const { shouldAutoOpen } = this.props;

        if (prevState.slipAddress !== slipAddress) {
            if (shouldAutoOpen && slipAddress !== noSlip && slipAddress !== '') {
                this.setState({ slipOpen: true });
            }
        }
    }

    handleClickOpen = (type) => {
        this.setState({ [type]: true });
    };

    handleClose = () => {
        this.setState({
            slipOpen: false,
            meteredOpen: false,
            currentWashOpen: false,
            previousWashOpen: false,
        });
        if (this.props.onClose) this.props.onClose();
    };

    setSlipURLState(slipData, stateKey) {
        if (slipData && slipData !== noSlip && slipData !== '') {
            getFileURL(slipData)
                .then((url) => {
                    this.setState({ [stateKey]: url });
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error(`Unable to set slip image URL for ${stateKey}`, err);
                });
        }
    }

    renderSlip(slipAddress, fullSlipURL, slipOpen, label, maxWidth = 'md') {
        const MenuButton = this.props.useModernButton ? ModernButton : Button;

        return (
            <div style={this.props.useModernButton ? { width: '100%' } : null}>
                <MenuButton
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleClickOpen(slipOpen, { ...this.props, ...this.state })}
                    className="my-16"
                    aria-label={`View ${label}`}
                    {...(this.props.useModernButton ? { fullWidth: true, className: null } : null)}
                    //
                >
                    {`View ${label}`}
                </MenuButton>
                <Dialog
                    open={this.state[slipOpen]}
                    onClose={this.handleClose}
                    PaperComponent={PaperComponent}
                    fullWidth
                    maxWidth={maxWidth}
                    aria-labelledby="draggable-dialog-title"
                    scroll="body"
                    //
                >
                    <DialogTitle id="draggable-dialog-title">{`${label}`}</DialogTitle>
                    <DialogContent>
                        {slipAddress.split('.')[1] === 'pdf' ? (
                            <DialogContentText>
                                <iframe
                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fullSlipURL)}&embedded=true`}
                                    style={{
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        minHeight: '70vh',
                                        maxHeight: '70vh',
                                    }}
                                    title={label}
                                />
                            </DialogContentText>
                        ) : (
                            <DialogContentText>
                                <img style={{ width: '100%', height: '30%' }} src={`${fullSlipURL}`} alt={`No ${label} found`} />
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    render() {
        const { type, useModernButton } = this.props;
        const {
            slipAddress,
            meteredAddress,
            currentWashSlipAddress,
            previousWashSlipAddress,
            fullSlipURL,
            fullMeteredURL,
            fullCurrentWashSlipURL,
            fullPreviousWashSlipURL,
            //
        } = this.state;

        const buttonVisible = (slipAddress && slipAddress !== noSlip) || (meteredAddress && meteredAddress !== noSlip) || (currentWashSlipAddress && currentWashSlipAddress !== noSlip) || (previousWashSlipAddress && previousWashSlipAddress !== noSlip);
        if (useModernButton && !buttonVisible) return null;

        return (
            <div
                style={{
                    margin: 'auto',
                    width: useModernButton ? '100%' : 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {slipAddress && slipAddress !== noSlip && this.renderSlip(slipAddress, fullSlipURL, 'slipOpen', `${type} Slip`)}
                {meteredAddress && meteredAddress !== noSlip && this.renderSlip(meteredAddress, fullMeteredURL, 'meteredOpen', 'Metered Slip', 'xs')}
                {currentWashSlipAddress && currentWashSlipAddress !== noSlip && this.renderSlip(currentWashSlipAddress, fullCurrentWashSlipURL, 'currentWashOpen', 'Current Outside Milk Wash Slip')}
                {previousWashSlipAddress && previousWashSlipAddress !== noSlip && this.renderSlip(previousWashSlipAddress, fullPreviousWashSlipURL, 'previousWashOpen', 'Previous Outside Milk Wash Slip')}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(null, mapDispatchToProps)(SlipDialog);
