/* eslint-disable import/prefer-default-export */

export const transactionCodes = [
    { name: 'Checking', value: '22' },
    { name: 'Savings', value: '32' },
];

export const taxCodes = [
    { name: 'SSN', value: 'social_security_number' },
    { name: 'EIN', value: 'employer_id_number' },
];

export const achRecordTypes = [
    { name: 'PPD', value: 'PPD' },
    { name: 'CCD', value: 'CCD' },
];
