import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { getCloseDayDetails, getCloseMonthDetails } from '../store/actions';
import { AbilityContext } from '../auth/permissions';
import LocalizedDatePicker from './LocalizedDatePicker';

const RestrictedDatePicker = ({
    value,
    onChange,
    ignoreLockPeriod,
    ampm = true,
    ...rest
    // wrap
}) => {
    const dispatch = useDispatch();
    const [rollbackDate] = useState(value ? moment(value) : moment());
    const [lockedDays, setLockedDays] = useState([]);
    const [lockedDaysList, setLockedDaysList] = useState([]);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const ignoreLock = ignoreLockPeriod || false;

    const ability = useContext(AbilityContext);
    const canAccessClosedMonth = ability?.can('mutate', 'close_month');

    useEffect(() => {
        let isMounted = true;
        const fetchList = () => {
            if (canAccessClosedMonth) {
                dispatch(getCloseMonthDetails({}))
                    .then(({ payload }) => {
                        if (!isMounted) return;
                        setLockedDays(payload.closedMonth.filter((month) => month.is_locked !== null));
                    })
                    .catch((err) => {
                        if (!isMounted) return;
                        dispatch(setError(err));
                    });
            } else {
                dispatch(getCloseDayDetails({}))
                    .then(({ payload }) => {
                        if (!isMounted) return;
                        setLockedDays(payload.closeDays);
                    })
                    .catch((err) => {
                        if (!isMounted) return;
                        dispatch(setError(err));
                    });
            }
        };
        fetchList();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        setLockedDaysList(lockedDays.filter((day) => day.is_locked).map((day) => moment(day.date).format('YYYY-MM-DD')));
    }, [lockedDays]);

    const checkIfDayLocked = (date) => {
        return lockedDaysList.includes(date.format('YYYY-MM-DD'));
    };

    return (
        <LocalizedDatePicker
            value={moment(value)}
            error={error}
            ampm={ampm}
            shouldDisableDate={!ignoreLock && checkIfDayLocked}
            onChange={(date) => {
                const isDisabled = !ignoreLock && checkIfDayLocked(date);
                if (!isDisabled) {
                    setError(false);
                    setHelperText('');
                    onChange?.(date);
                } else {
                    setError(true);
                    setHelperText('This date is locked');
                    onChange?.(rollbackDate);
                }
            }}
            slotProps={{
                textField: {
                    error,
                    helperText,
                },
            }}
            {...rest}
            // wrap
        />
    );
};

export default React.memo(RestrictedDatePicker);
