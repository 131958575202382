import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import moment from 'moment-timezone';
import LocalizedDatePicker from './LocalizedDatePicker';
import { DatePicker } from '@mui/x-date-pickers-pro';

const NewDatePicker = ({ setSelectedDate, selectedDate, startDate, disableFutureDate = true, disablePastDate = true, views, navigateTo, style, label }) => {
    let error;
    if (disableFutureDate && moment(selectedDate).isAfter(moment().endOf('month'))) {
        error = 'Date should not be after maximal date';
    } else if (disablePastDate && moment(selectedDate).isBefore(moment(startDate))) {
        error = 'Date should not be after minimal date';
    }

    return (
        <>
            <Button
                data-testid="new-date-picker-backward-button"
                onClick={() => {
                    setSelectedDate(selectedDate.clone().subtract(1, navigateTo === 'year' ? 'year' : 'month'));
                }}
                disabled={disablePastDate && moment(startDate).format('YYYY MM') === moment(selectedDate).format('YYYY MM')}
            >
                <Icon>navigate_before</Icon>
            </Button>
            <LocalizedDatePicker
                Picker={DatePicker}
                disableFuture={disableFutureDate}
                openTo="month"
                label={label ?? 'Select Date'}
                views={views ?? ['year', 'month']}
                value={selectedDate}
                minDate={startDate}
                onChange={(date) => {
                    setSelectedDate(date);
                }}
                slotProps={{ textField: { size: 'small', error, helperText: error, onClick: (e) => e.currentTarget.querySelector('button')?.click() }, field: { readOnly: true } }}
                sx={{ ...style }}
            />
            <Button
                data-testid="new-date-picker-forward-button"
                onClick={() => {
                    setSelectedDate(selectedDate.clone().add(1, navigateTo === 'year' ? 'year' : 'month'));
                }}
                disabled={disableFutureDate && moment().format('YYYY MM') === moment(selectedDate).format('YYYY MM')}
            >
                <Icon>navigate_next</Icon>
            </Button>
        </>
    );
};

export default React.memo(NewDatePicker);
