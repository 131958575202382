/* eslint-disable */
/**
 *
 * GoogleMaps
 *
 */

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, Circle, InfoWindow } from 'react-google-maps';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment-timezone';
import { Button } from '@mui/material';
import getRouteMapping from '../repository/getRouteMapping';
import _ from '@lodash';
import { LocalizationConsumer } from '../localization/LocalizationContext';
import GeofenceArtist_Deprecited from '../custom-components/geofences/shapes/GeofenceArtist_Depreciated';

function withStyles(Component) {
    return class extends React.Component {
        // make some enhancements
        render() {
            return (
                <div style={{ margin: window.innerWidth < 480 ? '0px 30px' : '0px' }} className={'fs-ignore-rage-clicks'}>
                    <Component {...this.props} />
                </div>
            );
        }
    };
}

class GoogleMaps extends React.Component {
    // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        const { region, overrideZoom } = this.props;
        this.state = {
            address: '',
            errorMessage: '',
            isGeocoding: false,
            path: null,
            selectedPinInfo: null,
            latestLocation: null,
            startLocation: null,
            mapData: null,
            truckPosition: null,
            center: null,
            zoom: overrideZoom ? overrideZoom : region && (region === 'PEI' || region === 'SM') ? 8 : 6,
            showAllFarms: region && region === 'RF',
        };
    }

    map = React.createRef();

    handlePositionChange = (e) => {
        const { onPositionChange } = this.props;
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        if (onPositionChange) {
            onPositionChange(lat, lng);
        }
    };

    handleChange = (address) => this.setState({ address });

    handleSelect = (selected) => {
        this.setState({ isGeocoding: true, address: selected });
        geocodeByAddress(selected)
            .then((res) => getLatLng(res[0]))
            .then(({ lat, lng }) => {
                this.setState({ isGeocoding: false });
                this.props.onPositionChange(lat, lng);
            })
            .catch((error) => {
                this.setState({ isGeocoding: false });
                console.warn('error', error); // eslint-disable-line no-console
            });
    };

    handleCenterChange = () => {
        const { updateLatLng } = this.props;
        updateLatLng ? updateLatLng(this.map.getCenter().lat(), this.map.getCenter().lng()) : null;
    };

    handleError = (status, clearSuggestions) => {
        console.warn('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    componentDidMount() {
        const { routeSessionId, overrideZoom, center, pickupsInfo, dropoffsInfo } = this.props;

        if (!!routeSessionId) {
            this.fetch();
        }

        if (!!center) {
            this.setState({ center: center });
        }

        if (overrideZoom && overrideZoom >= 10) {
            this.setState({ showAllFarms: true });
        }

        if (pickupsInfo && dropoffsInfo) {
            this.specifyZoomAndCenter(pickupsInfo.concat(dropoffsInfo));
        } else if (pickupsInfo) {
            this.specifyZoomAndCenter(pickupsInfo);
        } else if (dropoffsInfo) {
            this.specifyZoomAndCenter(pickupsInfo);
        }
    }

    specifyZoomAndCenter = (points) => {
        if (points.length > 0) {
            let maxLat = parseFloat(points[0].latitude);
            let minLat = parseFloat(points[0].latitude);
            let maxLng = parseFloat(points[0].longitude);
            let minLng = parseFloat(points[0].longitude);
            for (let i = 1; i < points.length; i += 1) {
                if (parseFloat(points[i].latitude) > maxLat) {
                    maxLat = parseFloat(points[i].latitude);
                }
                if (parseFloat(points[i].latitude) < minLat) {
                    minLat = parseFloat(points[i].latitude);
                }
                if (parseFloat(points[i].longitude) > maxLng) {
                    maxLng = parseFloat(points[i].longitude);
                }
                if (parseFloat(points[i].longitude) < minLng) {
                    minLng = parseFloat(points[i].longitude);
                }
            }
            this.setZoom(maxLng - minLng, maxLat - minLat);

            this.setState({ center: { lat: (maxLat + minLat) / 2, lng: (maxLng + minLng) / 2 } });
        }
    };

    fetch = () => {
        const { routeSessionId, updateLatLng, allowOnData, resetNeedFetch, needFetch } = this.props;
        resetNeedFetch();

        getRouteMapping(routeSessionId).then((mapping) => {
            if (mapping.routePoints && mapping.routePoints.length > 0) {
                let xYData = this.getXYExtremes(mapping.routePoints);
                allowOnData();
                if (!needFetch) {
                    updateLatLng(xYData.lat, xYData.lng);
                }
                this.setState({ mapData: mapping });
            }
        });
    };

    setZoom = (_lng, lat) => {
        if (lat < 0.038) {
            this.setState({ zoom: 12 });
        } else if (lat < 0.224) {
            this.setState({ zoom: 10 });
        } else if (lat < 0.448) {
            this.setState({ zoom: 9 });
        } else if (lat < 1.26) {
            this.setState({ zoom: 8 });
        } else if (lat < 4.0) {
            this.setState({ zoom: 7 });
        }
    };

    // redundant
    getXYExtremes = (points) => {
        const { setStartPoint, setEndPoint, setMinMaxTime } = this.props;
        if (setMinMaxTime && points[0] && points[0].time) {
            setMinMaxTime(points[0].time, points[points.length - 1].time);
        }
        setStartPoint({ lat: points[0].lat, lng: points[0].lng });
        setEndPoint({ lat: points[points.length - 1].lat, lng: points[points.length - 1].lng });
        let maxLng = points[0].lng;
        let minLng = points[0].lng;
        let maxLat = points[0].lat;
        let minLat = points[0].lat;
        for (let i = 0; i < points.length; i += 1) {
            if (points[i].lng > maxLng) {
                maxLng = points[i].lng;
            }
            if (points[i].lng < minLng) {
                minLng = points[i].lng;
            }
            if (points[i].lat > maxLat) {
                maxLat = points[i].lat;
            }
            if (points[i].lat < minLat) {
                minLat = points[i].lat;
            }
        }
        const lengthLng = Math.abs(parseFloat(maxLng) - parseFloat(minLng));
        const lengthLat = Math.abs(parseFloat(maxLat) - parseFloat(minLat));
        const midLng = (parseFloat(maxLng) + parseFloat(minLng)) / 2;
        const midLat = (parseFloat(maxLat) + parseFloat(minLat)) / 2;
        return { lat: midLat, lng: midLng, lengthLat, lengthLng };
    };

    componentDidUpdate(prevProps, prevState) {
        const { sliderValue, needFetch } = this.props;
        const { mapData } = this.state;

        if (needFetch) {
            this.fetch();
        }
        if (prevProps.sliderValue !== sliderValue && mapData) {
            let points = mapData.routePoints;
            if (!points[0].time) {
                this.setState({
                    truckPosition: {
                        lat: points[Math.min(Math.floor(((points.length - 1) * sliderValue) / 100), 100)].lat,
                        lng: points[Math.min(Math.floor(((points.length - 1) * sliderValue) / 100), 100)].lng,
                        time: points[Math.min(Math.floor(((points.length - 1) * sliderValue) / 100), 100)].time,
                    },
                });
            } else {
                const chosen = this.binarySearch(sliderValue, points);
                if (chosen) {
                    this.setState({
                        truckPosition: {
                            lat: chosen.lat,
                            lng: chosen.lng,
                            time: chosen.time,
                        },
                    });
                }
            }
        }

        if (mapData && mapData !== prevState.mapData && !needFetch) {
            let points = mapData.routePoints;
            let startIsPurple = false;
            let pivots = [0];
            let pathData = [];
            if (points[0].time) {
                const path = points.map(function (value) {
                    return {
                        lat: parseFloat(value.lat),
                        lng: parseFloat(value.lng),
                        time: moment(value.time).format('HH:mm A'),
                    };
                });
                if (points[0].generated) {
                    startIsPurple = true;
                }
                for (let i = 0; i < points.length; i += 1) {
                    if (i > 0 && points[i].generated !== points[i - 1].generated) {
                        pivots.push(i);
                    }
                }
                pivots.push(points.length - 1);
                for (let j = 1; j < pivots.length; j += 1) {
                    pathData.push({ start: pivots[j - 1], end: pivots[j], isPurple: (startIsPurple && j % 2 === 1) || (!startIsPurple && j % 2 === 0) });
                }
                this.setState({ path: path, pathData: pathData, startLocation: path[0], latestLocation: path[path.length - 1] });
            } else {
                const path = points.map(function (value) {
                    return {
                        lat: parseFloat(value.lat),
                        lng: parseFloat(value.lng),
                    };
                });
                this.setState({ path: path, startLocation: path[0], latestLocation: path[path.length - 1] });
            }

            if (!!this.props.setRouteInfo) {
                this.props.setRouteInfo(mapData);
            }
        }
    }

    binarySearch = (value, set) => {
        if (set.length === 1) {
            return set[0];
        } else if (parseFloat(set[Math.floor(set.length / 2)].time) === parseFloat(value)) {
            return set[Math.floor(set.length / 2)];
        } else if (parseFloat(set[Math.floor(set.length / 2)].time) > parseFloat(value)) {
            return this.binarySearch(value, set.slice(0, Math.ceil(set.length / 2)));
        } else if (parseFloat(set[Math.floor(set.length / 2)].time) < parseFloat(value)) {
            return this.binarySearch(value, set.slice(Math.floor(set.length / 2), set.length));
        }
    };

    selectPin = (_id, _type, value) => {
        this.setState({ selectedPinInfo: value });
    };

    updateStartPoint = (model) => {
        const { setStartPoint } = this.props;
        setStartPoint({ lat: model.latLng.lat(), lng: model.latLng.lng() });
    };

    updateEndPoint = (model) => {
        const { setEndPoint } = this.props;
        setEndPoint({ lat: model.latLng.lat(), lng: model.latLng.lng() });
    };

    determineShow = () => {
        const { showAllFarms } = this.state;

        if (this.map.getZoom && this.map.getZoom() === 10 && (showAllFarms || !showAllFarms)) {
            this.setState({ showAllFarms: this.map.getZoom() === 10, center: { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() } });
        }
    };

    render() {
        const uuidv4 = require('uuid/v4');
        const { lat, lng, isMarkerShown, draggable, pickupsInfo, dropoffsInfo, driverLocations, processorLocations, allowEdit, startPin, endPin, geofences, circleData, producerLocations, pinFunction, defaultToRoadMap, region, hideSearch } = this.props;
        const { address, path, selectedPinInfo, startLocation, latestLocation, truckPosition, zoom, showAllFarms, center, pathData } = this.state;
        const currentPosition = { lat: Number(lat), lng: Number(lng) };

        const producerName = selectedPinInfo?.producer?.[0]?.name || selectedPinInfo?.name.split('-')[0] || 'Unknown Producer';

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="block">
                        <GoogleMap zoom={zoom} center={center ? center : currentPosition} ref={(map) => (this.map = map)} defaultOptions={{ scrollwheel: false }} onDragEnd={this.handleCenterChange} onZoomChanged={() => this.determineShow()} mapTypeId={defaultToRoadMap ? 'roadmap' : 'satellite'}>
                            {isMarkerShown && <Marker onDragEnd={this.handlePositionChange} defaultDraggable={draggable} draggable={draggable} position={currentPosition} />}

                            {!!geofences?.length && <GeofenceArtist_Deprecited geofences={geofences} />}

                            {circleData &&
                                circleData.map((value) => (
                                    <div key={uuidv4()}>
                                        <Circle key={uuidv4()} radius={value.radius} center={new google.maps.LatLng(value.latitude, value.longitude)} defaultCenter={new google.maps.LatLng(lat, lng)} title={value.name} options={{ strokeColor: '#ff0000' }} visible={showAllFarms} />
                                        {value.showPin && <Marker key={uuidv4()} position={{ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }} title={value.name} icon={'https://maps.google.com/mapfiles/ms/micons/yellow-dot.png'} visible={showAllFarms} onClick={this.selectPin.bind(this, value.id, 'driver', value)} />}
                                    </div>
                                ))}

                            {(!!path || !!pickupsInfo || !!dropoffsInfo || !!driverLocations || !!processorLocations || !!selectedPinInfo || !!producerLocations) && (
                                <div>
                                    {!!pickupsInfo &&
                                        pickupsInfo.map((value) => (
                                            <Marker
                                                key={uuidv4()}
                                                position={{ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }}
                                                title="Pickup"
                                                icon={value.volume ? 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                                                onClick={this.selectPin.bind(this, value.id, 'pickup', value)}
                                            />
                                        ))}

                                    {!!dropoffsInfo && dropoffsInfo.map((value) => <Marker key={uuidv4()} position={{ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }} title="Dropoff" icon={'https://maps.google.com/mapfiles/ms/micons/orange-dot.png'} onClick={this.selectPin.bind(this, value.id, 'dropoff', value)} />)}

                                    {!!producerLocations &&
                                        producerLocations.map((value) => (
                                            <Marker
                                                key={uuidv4()}
                                                position={{ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }}
                                                title={`${value?.label || value?.name || 'Unknown Producer'}`}
                                                icon={value.isSelected ? 'https://maps.google.com/mapfiles/ms/icons/red-dot.png' : 'https://maps.google.com/mapfiles/ms/micons/ltblue-dot.png'}
                                                onClick={this.selectPin.bind(this, value.value, 'pickup', value)}
                                            />
                                        ))}

                                    {!!driverLocations && driverLocations.map((value) => <Marker key={uuidv4()} position={{ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }} title="Driver" icon={'https://maps.google.com/mapfiles/ms/micons/truck.png'} onClick={this.selectPin.bind(this, value.id, 'driver', value)} />)}
                                    {!!processorLocations &&
                                        processorLocations.map((value) => (
                                            <Marker key={uuidv4()} position={{ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }} title={localization.general.processor_singular} icon={'https://maps.google.com/mapfiles/ms/micons/yellow-dot.png'} onClick={this.selectPin.bind(this, value.id, 'processor', value)} />
                                        ))}

                                    {!!selectedPinInfo && (
                                        <InfoWindow
                                            position={{ lat: parseFloat(selectedPinInfo.latitude), lng: parseFloat(selectedPinInfo.longitude) }}
                                            onCloseClick={() => {
                                                this.setState({ selectedPinInfo: null });
                                            }}
                                        >
                                            <div>
                                                {(!!selectedPinInfo?.producer || selectedPinInfo?.role === 'producer') && <h3>{producerName}</h3>}
                                                {!!selectedPinInfo.processor && <h2>{!!selectedPinInfo.processor[0] ? selectedPinInfo.processor[0].name : 'Unknown Processor'}</h2>}
                                                {!!selectedPinInfo.driver_name && <h2>{selectedPinInfo.driver_name}</h2>}
                                                {!!selectedPinInfo.processor_name && <h2>{selectedPinInfo.processor_name}</h2>}
                                                {region === 'CDI' && !!selectedPinInfo.hauling_company_name && <h2 className="text-blue">{selectedPinInfo.hauling_company_name}</h2>}

                                                {!!selectedPinInfo.volume && (
                                                    <p>
                                                        <b>{'Amount: ' + selectedPinInfo.volume}</b>
                                                    </p>
                                                )}

                                                {!!selectedPinInfo.route_name && (
                                                    <p>
                                                        <b>{'Route: ' + selectedPinInfo.route_name}</b>
                                                    </p>
                                                )}

                                                {!!selectedPinInfo.license_number && (
                                                    <p>
                                                        <b>{'License Number: ' + selectedPinInfo.license_number}</b>
                                                    </p>
                                                )}

                                                {!!selectedPinInfo.circlePoint && <h3>{selectedPinInfo.name}</h3>}

                                                {!!selectedPinInfo.created_at && (
                                                    <p>
                                                        <b>{'Updated: ' + moment(selectedPinInfo.created_at).format('MMMM Do, h:mm a')}</b>
                                                    </p>
                                                )}

                                                {!!Object.keys(selectedPinInfo).includes('isSelected') && (
                                                    <div>
                                                        <p>
                                                            <b>{selectedPinInfo.label}</b>
                                                        </p>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            sx={{ fontSize: '12px', mt: 1 }}
                                                            onClick={() => {
                                                                pinFunction(selectedPinInfo);
                                                                this.setState({ selectedPinInfo: null });
                                                            }}
                                                        >
                                                            {selectedPinInfo.isSelected ? 'Remove From Route' : 'Add to Route'}
                                                        </Button>
                                                    </div>
                                                )}

                                                {selectedPinInfo.session_id && (
                                                    <div>
                                                        <p>
                                                            <b>{selectedPinInfo.label}</b>
                                                        </p>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{ fontSize: '12px' }}
                                                            onClick={() => {
                                                                pinFunction(selectedPinInfo);
                                                                this.setState({ selectedPinInfo: null });
                                                            }}
                                                        >
                                                            {`View ${localization.general.routeSession}`}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </InfoWindow>
                                    )}

                                    {!!startLocation && <Marker key={uuidv4()} position={{ lat: parseFloat(startPin.lat), lng: parseFloat(startPin.lng) }} onDragEnd={this.updateStartPoint} title="Start of Route" draggable={allowEdit} icon={'https://maps.google.com/mapfiles/ms/micons/red-pushpin.png'} />}

                                    {!!latestLocation && (
                                        <Marker
                                            key={uuidv4()}
                                            position={{ lat: allowEdit ? parseFloat(endPin.lat) : truckPosition ? parseFloat(truckPosition.lat) : parseFloat(latestLocation.lat), lng: allowEdit ? parseFloat(endPin.lng) : truckPosition ? parseFloat(truckPosition.lng) : parseFloat(latestLocation.lng) }}
                                            onDragEnd={this.updateEndPoint}
                                            title="Driver"
                                            draggable={allowEdit}
                                            icon={'https://maps.google.com/mapfiles/ms/micons/truck.png'}
                                        />
                                    )}
                                    {pathData &&
                                        path &&
                                        pathData.map((value) => (
                                            <Polyline
                                                key={uuidv4()}
                                                path={path.slice(value.start, value.end + 1)}
                                                geodesic={true}
                                                options={{
                                                    strokeColor: value.isPurple ? '#6222D9' : '#ff2527',
                                                    strokeOpacity: 0.75,
                                                    strokeWeight: 3,
                                                }}
                                            />
                                        ))}
                                </div>
                            )}
                        </GoogleMap>

                        {!hideSearch && (
                            <PlacesAutocomplete onChange={this.handleChange} value={address} onSelect={this.handleSelect} onError={this.handleError} shouldFetchSuggestions={address.length > 2}>
                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'location-search-input',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown">
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                const style = suggestion.active ? { padding: '5px 7px', backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div {...getSuggestionItemProps(suggestion, { className, style })}>
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        )}
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

GoogleMaps.defaultProps = {
    lat: 49.578749,
    lng: -56.00428,
};

export default withStyles(withScriptjs(withGoogleMap(GoogleMaps)));
