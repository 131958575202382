import axios from 'axios';
import _ from 'lodash';
import store from 'app/store';
import moment from 'moment-timezone';
import { getTrucksQuery, getDropoffsQuery, getProcessorsQuery, getRouteSessionsQuery, getPickupsQuery, getRoutesQuery, getLabReportsQuery, batchQuery, getHaulingCompaniesQuery, getTrailersQuery, getUsersQuery, getSilosQuery } from './queries';
import getPickupsByDropoffId from './utils/getPickupsByDropoffId';
import calculateComponents from './utils/calculateComponents';
import { getBatchedQueryData, getBulkQueryData } from './utils';
import { arrayToMap, formatLabsForComponentCalculation, getNumSlips } from '../../utils';
import { calculateShrinkAmount, calculateShrinkPercentage } from '../helpers/dropoffs/utils';

/**
 * For clients who have receivers adding dropoffs we need to get the
 * driver and hauling company info from the route session instead of the
 * dropoff
 */
const hasActiveReceivers = (region) => {
    switch (region) {
        case 'PRAIRIE':
            return true;
        default:
            return false;
    }
};

const requiresLabData = (region) => {
    switch (region) {
        case 'UDA':
            return true;
        default:
            return false;
    }
};

const requiresNLSpecificProcessorDropoffRelation = (region) => {
    switch (region) {
        case 'NL':
            return true;
        default:
            return false;
    }
};

/**
 *
 * Creates a query to retrieve dropoffs, bounded by start and end if provided
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} dropoffsQuery params bounded by start/end if provided, else to 100 records
 */
const generateBoundedDropoffsQueryParams = (start, end, filterType) => {
    return start && end
        ? {
              start,
              end,
              filterDeleted: true,
              filterType,
          }
        : {
              limit: '100',
              filterDeleted: true,
              filterType,
          };
};

/**
 * Get dropoffs first, and then all routeSessions corresponding to those dropoffs
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} { dropoffs, routeSessions }
 */
const getDropoffsThenRouteSessions = async (start, end, filterType) => {
    const dropoffs = await getBulkQueryData(getDropoffsQuery, generateBoundedDropoffsQueryParams(start, end, filterType));
    const boundedStart = moment(start).subtract(1, 'week');
    const boundedEnd = moment(end).add(1, 'week');

    const linkingIds = _.uniq(_.map(dropoffs, 'route_session_id'));

    const routeSessions = await getBulkQueryData(getRouteSessionsQuery, {
        start: start && boundedStart,
        end: end && boundedEnd,
        fields: ['id', 'BOL', 'status', 'route_id', 'manifest_number', 'trailer_id', 'hauling_id', 'created_by', 'truck_id', 'po_number'],
        ids: linkingIds,
        hint: '_id_1_created_at_-1_deleted_at_1',
    });

    return { dropoffs, routeSessions };
};

/**
 * Get lab reports from pickups
 *
 * @param {array} pickups
 * @returns {array} labReports
 */
const getLabReports = async (pickups) => {
    const firstPickup = _.minBy(pickups, 'created_at');
    const lastPickup = _.maxBy(pickups, 'created_at');

    const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : null;
    const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : null;

    const producerIds = _.uniq(_.map(pickups, 'producer_id'));
    return getBatchedQueryData(
        getLabReportsQuery,
        {
            fields: ['producer_id', 'date', 'fat', 'LOS', 'somatic_cell_count', 'protein', 'lactose', 'other_solids', 'sample_barcode', 'pickup_id'],
            start: labReportStart,
            end: labReportEnd,
            producerIds,
        },
        'producerIds'
    );
};

/**
 * Get pickups by dropoffs by routeSessionId
 *
 * @param {array} pickups
 * @param {array} dropoffs
 * @param {array} routeSessionIds
 * @returns {object} pickups by dropoffs by routeSessionId
 */
const getPickupsByDropoffIdByRouteSessionId = (pickups, dropoffs, routeSessionIds) => {
    const pickupsByRouteSessionId = _.groupBy(pickups, 'route_session_id');
    const dropoffsByRouteSessionId = _.groupBy(dropoffs, 'route_session_id');

    const pickupsDropoffsByRouteSessionId = _.mapValues(
        _.groupBy(
            _.map(routeSessionIds, (id) => {
                const rsDropoffs = dropoffsByRouteSessionId[id];
                const rsPickups = pickupsByRouteSessionId[id] ? pickupsByRouteSessionId[id] : [];
                return {
                    route_session_id: id,
                    dropoffs: rsDropoffs,
                    pickups: rsPickups,
                };
            }),
            'route_session_id'
        ),
        (o) => _.pick(o[0], ['pickups', 'dropoffs'])
    );

    const pickupsByDropoffIdByRouteSessionId = _.mapValues(
        _.groupBy(
            _.map(routeSessionIds, (id) => {
                const { pickups: p, dropoffs: d } = pickupsDropoffsByRouteSessionId[id];
                return { route_session_id: id, pickupsByDropoffId: d ? getPickupsByDropoffId(d, p) : [] };
            }),
            'route_session_id'
        ),
        (o) => o[0].pickupsByDropoffId
    );

    return pickupsByDropoffIdByRouteSessionId;
};

/**
 * Get dropoff totals by processor id, as well as overall total for all processors (but not all dropoffs)
 *
 * @param {array} processors
 * @param {object} dropoffsByProcessor
 * @returns {object} dropoff totals by processor id, as well as overall total for all processors (but not all dropoffs)
 */
const getDropoffTotalsByProcessor = (processors, dropoffsByProcessor) => {
    const dropoffTotals = { total: { amount: 0, count: 0 } };
    processors.forEach((processor) => {
        dropoffTotals[processor.name] = { amount: _.sumBy(dropoffsByProcessor[processor.id], 'volume'), count: dropoffsByProcessor[processor.id].length };
        dropoffTotals.total.amount += _.sumBy(dropoffsByProcessor[processor.id], 'volume');
        dropoffTotals.total.count += dropoffsByProcessor[processor.id].length;
    });

    return dropoffTotals;
};

/**
 * Get details required to populate Dropoffs page
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} { dropoffs, dropoffTotals }
 */
const getDropoffDetails = async (start, end, filterType = 'milk') => {
    try {
        const { region } = store.getState().persisted.auth.user.data;

        const { dropoffs, routeSessions } = await getDropoffsThenRouteSessions(start, end, filterType);
        const routeSessionIds = _.uniq(_.map(routeSessions, 'id'));
        const pickups = await getBulkQueryData(getPickupsQuery, { routeSessionIds, filterDeleted: true }, 'routeSessionIds');

        const driverIds = hasActiveReceivers(region) ? _.uniq(_.map(routeSessions, 'created_by')) : _.uniq(_.map(dropoffs, 'driver_id'));
        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
        const truckIds = _.uniq(_.map(routeSessions, 'truck_id'));

        const driversRequest = getBulkQueryData(getUsersQuery, { fields: ['id', 'name', 'hauling_id'], ids: driverIds }, 'ids');
        const processorsRequest = getBulkQueryData(getProcessorsQuery, { fields: ['id', 'name', 'latitude', 'longitude', 'geofence', 'license_number'], ids: processorIds }, 'ids');
        const routesRequest = getBulkQueryData(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');
        const silosRequest = axios.get(getSilosQuery({ filterDeleted: true }));

        const [drivers, processors, routes, silos] = await Promise.all([driversRequest, processorsRequest, routesRequest, silosRequest]);

        const haulingIds = _.filter(_.uniq(_.map(drivers, 'hauling_id')), (id) => !_.isNull(id));

        const haulingCompanyRequest = getBulkQueryData(getHaulingCompaniesQuery, { fields: ['id', 'name'], ids: haulingIds });
        const trailerRequest = getBatchedQueryData(getTrailersQuery, { ids: trailerIds }, 'ids');
        const truckRequest = axios.get(getTrucksQuery({ ids: truckIds }));

        const [haulers, trailers, trucks] = await Promise.all([haulingCompanyRequest, trailerRequest, truckRequest]);

        const driversMap = arrayToMap(drivers, 'id');
        const processorsMap = arrayToMap(processors, 'id');
        const routeSessionsMap = arrayToMap(routeSessions, 'id');
        const routesMap = arrayToMap(routes, 'id');
        const haulingMap = arrayToMap(haulers, 'id');
        const siloMap = arrayToMap(silos?.data || [], 'id');
        const trucksMap = arrayToMap(trucks?.data || [], '_id');
        const trailerMap = arrayToMap(trailers, 'id');

        let data = [];
        if (requiresLabData(region)) {
            const labReports = await getLabReports(pickups);
            const pickupsByDropoffIdByRouteSessionId = getPickupsByDropoffIdByRouteSessionId(pickups, dropoffs, routeSessionIds);
            const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

            data = _.map(dropoffs, (dropoff) => {
                const processor = [processorsMap[dropoff.processor_id]];
                const driver = [driversMap[dropoff.driver_id]];
                const routeSession = routeSessionsMap[dropoff.route_session_id];
                const trailer = trailerMap?.[routeSessionsMap?.[dropoff?.route_session_id || dropoff?.route_session]?.trailer_id]?.trailer_number || '';
                const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

                // Combine the data from the Dropoff/Pickup's split details with the split details info from the Silo Query
                const split_details = dropoff?.split_details?.map((silo) => ({
                    ...silo,
                    ...siloMap[silo.silo],
                }));

                const pickupsForDropoff = pickupsByDropoffIdByRouteSessionId?.[routeSession?.id]?.[dropoff?.id] || [];
                const components = calculateComponents(pickupsForDropoff, groupedLabReports, region, labsByPickupIdMap);
                return {
                    ...dropoff,
                    split_details,
                    shrink_amount: calculateShrinkAmount(dropoff),
                    shrink_percent: calculateShrinkPercentage(dropoff),
                    components,
                    processor,
                    driver,
                    trailer,
                    route_session: [{ ...routeSession, route: routeSession && routes && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                    num_slips: getNumSlips(dropoff),
                    truck_number,
                };
            });
        } else {
            data = _.map(dropoffs, (dropoff) => {
                const processor = [processorsMap[dropoff.processor_id]];
                const routeSession = routeSessionsMap[dropoff.route_session_id];
                const driver = hasActiveReceivers(region) ? [driversMap[routeSession?.created_by]] : [driversMap[dropoff.driver_id]];
                const hauling_company = hasActiveReceivers(region) ? haulingMap?.[routeSession?.hauling_id]?.name : haulingMap?.[driversMap[dropoff?.driver_id]?.hauling_id]?.name || haulingMap?.[routeSession?.hauling_id]?.name || '';
                const trailer = trailerMap?.[routeSessionsMap?.[dropoff?.route_session_id || dropoff?.route_session]?.trailer_id]?.trailer_number || '';
                const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

                const split_details = dropoff?.split_details?.map((silo) => ({
                    ...silo,
                    ...siloMap[silo.silo],
                }));
                return {
                    ...dropoff,
                    split_details,
                    shrink_amount: calculateShrinkAmount(dropoff),
                    shrink_percent: calculateShrinkPercentage(dropoff),
                    processor,
                    driver,
                    hauling_company,
                    trailer,
                    route_session: [{ ...routeSession, route: routeSession && routes && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                    num_slips: getNumSlips(dropoff),
                    truck_number,
                };
            });
        }

        let dropoffsByProcessor = [];
        if (requiresNLSpecificProcessorDropoffRelation(region)) {
            const processorDropoffs = _.map(dropoffs, (dropoff) => {
                const processor = processorsMap[dropoff.processor_id];
                return { ...dropoff, processor };
            });
            dropoffsByProcessor = _.groupBy(processorDropoffs, 'processor_id');
        } else {
            dropoffsByProcessor = _.groupBy(data, 'processor_id');
        }

        const dropoffTotals = getDropoffTotalsByProcessor(processors, dropoffsByProcessor);

        return { dropoffs: data, dropoffTotals };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getDropoffDetails;
