import React from 'react';
import { Button } from '@material-ui/core';
import { PayReportDownloadStatus } from 'app/constants/report';
import { DOES_NOT_USE_CHECK_NUMBERS_REGIONS } from '../../constants/region-utils';
import { getUserRegion } from '../../../utils';

// Only used on Hauler and Vendor pages, therefore we can assume that paymentType cannot be deferral, and all tasks are async
const PayReportDownloadButtons = ({ canStartTask, dispatchTask, setCheckNumberDialogOpen, setDialogOpen, paymentType, payReports }) => {
    const region = getUserRegion();

    const checkIfReportsAreApproved = () => {
        return payReports[paymentType]?.some((report) => report?.report_data?.[`approved_${paymentType}_report`] === true);
    };

    const buttonShouldBeDisabled = () => {
        return checkIfReportsAreApproved() || !canStartTask;
    };

    const handleDownloadAll = (payReportDownloadStatus) => {
        // Prevent check numbers from being reassigned to approved reports
        if (!checkIfReportsAreApproved() && !DOES_NOT_USE_CHECK_NUMBERS_REGIONS.includes(region) && payReportDownloadStatus === PayReportDownloadStatus.download) {
            setCheckNumberDialogOpen(true);
        } else {
            dispatchTask({ payReportDownloadStatus });
        }
    };

    return (
        <div>
            <Button color="secondary" variant="contained" className="mr-12" disabled={buttonShouldBeDisabled()} onClick={() => handleDownloadAll(PayReportDownloadStatus.calculate)}>
                Calculate Pay
            </Button>
            <Button color="secondary" variant="contained" disabled={buttonShouldBeDisabled()} onClick={() => handleDownloadAll(PayReportDownloadStatus.download)}>
                Download All
            </Button>
            <Button color="secondary" variant="contained" className="ml-12" disabled={buttonShouldBeDisabled()} onClick={() => setDialogOpen(true)}>
                Approve All
            </Button>
        </div>
    );
};

export default PayReportDownloadButtons;
