import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import moment from 'moment-timezone';

// eslint-disable-next-line import/prefer-default-export
export const useScheduleSettings = create(
    persist(
        (set) => ({
            settings: {
                view: 'week',
                selectedDay: moment(),
                showDashboard: false,
                expandedRows: [],
            },
            filters: {
                start: moment(),
                range: 'week',
                groupBy: 'start_date',
                order: 'start_date ASC',
                search: {
                    'route_id.name': '',
                },
                processor_id: [],
                hauling_id: [],
                milk_category: [],
            },
            setSettings: (callback) =>
                set(({ settings }) => ({
                    settings: callback(settings),
                })),
            setFilters: (callback) =>
                set(({ filters }) => ({
                    filters: callback(filters),
                })),
        }),
        {
            name: 'schedule-settings',
            storage: createJSONStorage(() => localStorage, {
                reviver: (_key, value) => {
                    // Guard against replacer not running and just the ISO string being saved
                    const isIsoString = moment(value, moment.ISO_8601, true).isValid();
                    const hasTime = /T\d{2}:\d{2}:\d{2}/.test(value);
                    const savedIsoString = isIsoString && hasTime;
                    if (value && (value.type === 'moment' || savedIsoString)) {
                        return moment(value);
                    }
                    return value;
                },
                replacer: (_key, value) => {
                    if (moment.isMoment(value)) {
                        return { type: 'moment', value: value.toISOString() };
                    }
                    return value;
                },
            }),
            partialize: (state) => ({
                settings: state.settings,
                filters: state.filters,
            }),
        }
    )
);
