import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragHandle, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import {
    styled,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    TextField,
    Stack,
    Box,
    // wrap
} from '@mui/material';

const DraggableWrap = styled(Box)(() => ({
    backgroundColor: '#FAFAFA',
    borderRadius: 5,
    maxHeight: 470,
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center',
}));

const DraggableListItem = styled(ListItem)(() => ({
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function ProducerPickupSequence({ onChange, fields }) {
    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reordered = reorder(fields, result.source.index, result.destination.index);
        onChange(reordered);
    };

    const moveItem = (index, direction) => {
        const reordered = reorder(fields, index, index + direction);
        onChange(reordered);
    };

    const handleAmountChange = (index, event) => {
        const field = fields[index];
        field.amount = event.target.value;
        onChange(fields);
    };

    if (!fields.length) {
        return (
            <Stack alignItems="center">
                <Typography>No Producers Selected</Typography>
            </Stack>
        );
    }

    return (
        <Box sx={{ marginTop: 1 }}>
            <Box sx={{ display: 'flex', px: 2 }}>
                <Typography
                    variant="body1"
                    fontWeight={600}
                    component="div"
                    sx={{ flex: 2 }}
                    //
                >
                    Order
                </Typography>
                <Typography
                    variant="body1"
                    fontWeight={600}
                    component="div"
                    sx={{ flex: 4 }}
                    //
                >
                    Producer
                </Typography>
                <Typography
                    variant="body1"
                    fontWeight={600}
                    component="div"
                    sx={{ flex: 4 }}
                    //
                >
                    Amount
                </Typography>
            </Box>
            <DraggableWrap>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable-list">
                        {(droppable) => (
                            <List {...droppable.droppableProps} sx={{ width: '100%', px: 2 }} ref={droppable.innerRef}>
                                {fields.map((field, index) => (
                                    <Draggable key={field.id} draggableId={field.id} index={index}>
                                        {(draggable) => (
                                            <DraggableListItem
                                                ref={draggable.innerRef}
                                                {...draggable.draggableProps}
                                                {...draggable.dragHandleProps}
                                                //
                                            >
                                                <Stack direction="row" spacing={1}>
                                                    <Typography variant="body1" component="div" sx={{ flex: 1 }}>
                                                        {index + 1}
                                                    </Typography>
                                                    <ListItemIcon>
                                                        <DragHandle />
                                                    </ListItemIcon>
                                                </Stack>
                                                <ListItemText primary={field.name} sx={{ flex: 4 }} />
                                                <TextField type="number" value={field.amount} onChange={(e) => handleAmountChange(index, e)} sx={{ flex: 3 }} />
                                                <IconButton disabled={index === 0} onClick={() => moveItem(index, -1)}>
                                                    <ArrowUpward />
                                                </IconButton>
                                                <IconButton disabled={index === fields.length - 1} onClick={() => moveItem(index, 1)}>
                                                    <ArrowDownward />
                                                </IconButton>
                                            </DraggableListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {droppable.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </DraggableWrap>
        </Box>
    );
}
export default ProducerPickupSequence;
