import React, { useMemo } from 'react';
import { FormControl, Select, MenuItem, CircularProgress } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import getUsersName from '../../../helpers/users/utils';
import { useFetchUsers } from './queries';
import configs from './configs';

const EditUserCell = ({ id, field, row, query, secondaryLabelField, table, includeUnassigned = true, sortFn }) => {
    const apiRef = useGridApiContext();
    const { data: rawData, isLoading } = useFetchUsers(query);
    const data = useMemo(() => {
        return sortFn ? rawData?.toSorted(sortFn) : rawData;
    }, [rawData, sortFn]);

    const mutateConfig = configs[table];
    const { mutateAsync } = mutateConfig.useHook();

    const handleMutate = async (event) => {
        const { value } = event.target;

        const extractIdFields = mutateConfig.schema.meta().extractIdFields || [];
        const formattedRow = {
            ...row,
            [field]: value,
        };

        extractIdFields.forEach((_field) => {
            if (formattedRow[_field] && formattedRow[_field]._id) {
                formattedRow[_field] = formattedRow[_field]._id;
            }
        });

        const body = mutateConfig.schema.validateSync(formattedRow, { stripUnknown: true });

        apiRef.current.setEditCellValue({
            id,
            field,
            value,
        });

        mutateConfig.handleMutate({ id, body }, { mutate: mutateAsync });
        return apiRef.current.setCellMode(id, field, 'view');
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <FormControl
            fullWidth
            variant="outlined"
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                },
            }}
        >
            <Select autoFocus fullWidth value={row[field]?._id ?? ''} onChange={handleMutate}>
                {includeUnassigned && <MenuItem value={undefined}>Unassigned</MenuItem>}
                {data.map((user) => {
                    const userName = getUsersName(user);
                    const secondaryLabel = secondaryLabelField ? user[secondaryLabelField] : null;
                    const label = [userName, secondaryLabel].filter(Boolean).join(' - ');

                    return (
                        <MenuItem key={user._id} value={user._id}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default EditUserCell;
