import { Box, Card, Typography } from '@mui/material';
import React, { useState } from 'react';
import SymbolDropoff from '../../symbols/SymbolDropoff';
import SymbolPickup from '../../symbols/SymbolPickup';
import moment from 'moment';
import { toAmountString } from '../../../schedule2/utils';

const TransferDetails = ({ name, volume, date, isDropoff }) => {
    const [showInfo, setShowInfo] = useState(false);

    const handleMouseEnter = () => {
        if (name || volume) setShowInfo(true);
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    return (
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Box sx={{ zIndex: 1 }}>{isDropoff ? <SymbolDropoff /> : <SymbolPickup />}</Box>
            {showInfo && (
                <Card sx={{ position: 'absolute', left: 25, top: '50%', transform: 'translateY(-50%)', zIndex: 50 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', margin: '8px', width: '150px' }}>
                        <Typography variant="subtitle1">{name}</Typography>
                        <Typography variant="subtitle2">{volume ? `${toAmountString(volume)} lbs` : ' - '}</Typography>
                        <Typography variant="p">{moment(date).format('MMMM Do, YYYY - h:mm a')}</Typography>
                    </Box>
                </Card>
            )}
        </Box>
    );
};

export default TransferDetails;
