import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, IconButton, Tooltip } from '@mui/material';
import DeleteDialog from './DeleteDialog';
import SlipDialog from './SlipDialog';
import MatchLabDialog from './MatchLabDialog';
import { useHistory } from 'react-router';
import { OpenInNew } from '@material-ui/icons';

const IconButtonSx = {
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
    borderRadius: '4px',
    '&:hover': { backgroundColor: (theme) => theme.palette.primary.dark },
};

export default function DataTableDialog({ open, onClose, actions, rowData, forceFetch }) {
    const history = useHistory();

    const evaluate = (possibleFn) => {
        return typeof possibleFn === 'function' ? possibleFn({ rowData }) : possibleFn;
    };

    const handleRedirect = (url) => {
        history.push(evaluate(url));
    };

    const handleOpenTab = (url) => {
        const tab = window.open(`/#${evaluate(url)}`, '_blank');
        tab.focus();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Selection Options</DialogTitle>
            <DialogContent>
                <Stack gap={2} sx={{ marginX: 8, marginTop: 2 }}>
                    {actions.redirects?.map(({ title, url, disable = false, visible = true }) => {
                        return evaluate(visible) ? (
                            <Stack direction="row" gap={1} sx={{ width: '100%' }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={evaluate(disable)}
                                    onClick={() => handleRedirect(url)}
                                    aria-label={title}
                                    // wrap
                                >
                                    {title}
                                </Button>
                                <Tooltip title={`${title} In New Tab`} enterDelay={500}>
                                    <IconButton sx={IconButtonSx} onClick={() => handleOpenTab(url)} disabled={evaluate(disable)}>
                                        <OpenInNew />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        ) : null;
                    })}
                    {actions.slip && (
                        <SlipDialog
                            data={rowData}
                            type={actions.slip.type}
                            useModernButton
                            // wrap
                        />
                    )}
                    {actions.matchLab && (
                        <MatchLabDialog
                            data={rowData}
                            shouldAutoOpen={false}
                            forceFetch={forceFetch}
                            disabled={evaluate(actions.matchLab.disable)}
                            useModernButton
                            // wrap
                        />
                    )}
                    {actions.delete && evaluate(actions.delete.visible ?? true) && (
                        <DeleteDialog
                            title={actions.delete.title}
                            dataType={actions.delete.dataType}
                            data={rowData}
                            forceFetch={forceFetch}
                            disable={evaluate(actions.delete.disable)}
                            buttonSx={{ width: '100%', margin: 0 }}
                            // wrap
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
