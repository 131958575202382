import { useContext } from 'react';
import moment from 'moment-timezone';
import { useQuery, useQueries, useMutation, useQueryClient } from '@tanstack/react-query';
import { expressMutation, useExpressQuery } from '../../../../useExpress';
import { NotificationContext } from '../../../../NotificationContext';
import { buildFieldFilter, getSendScheduleQuery } from '../../../../repository/queries';
import { isHauler, isProcessor } from '../../../../../utils';
import { getScheduleDateRange } from './common/utils';
import { useScheduleData } from './ScheduleDataContext';

const userKeys = ['processor_id', 'hauling_id', '_id'];
const filterUsers = (users) => users.filter((v) => v !== 'select_all');
const errorMessage = 'An error occurred updating the schedule. Please try again or contact support if the problem persists.';

const getParams = ({ queryParams, dateField }) => {
    const { groupBy, start, end, range, order, search, fields, populate, selectedDay, ...filters } = queryParams;
    const where = { ...filters, deleted_at: { eq: null } };

    if (start && range && dateField) {
        const adjustedStart = range === 'day' ? (selectedDay || start).clone() : start;

        const { startDate, endDate } = getScheduleDateRange(adjustedStart, range);

        where[dateField] = { between: [startDate, endDate] };
    }
    Object.keys(where).forEach((key) => {
        let value = where[key];
        if (userKeys.includes(key)) value = filterUsers(value);
        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete where[key];
            } else {
                where[key] = { inq: value };
            }
        }
    });

    return {
        order,
        populate,
        where,
        fields: buildFieldFilter(fields),
        filter: { search },
    };
};

const getQueryKey = (queryParams) => {
    const { groupBy, start, range, order, search, fields, populate, selectedDay, ...filters } = queryParams;
    let queryKey = { ...queryParams };
    Object.keys(queryParams).forEach((key) => {
        let value = queryKey[key];
        if (!value) return '';
        if (moment.isMoment(value)) queryKey[key] = value.toISOString();
        if (typeof value !== 'string') queryKey[key] = JSON.stringify(value);
    });
    return queryKey;
};

const getRoutes = ({ queryParams }) => {
    const endpoint = '/routes';
    const params = getParams({ queryParams });
    return useExpressQuery({ endpoint, params });
};

export const useGetRoutes = ({ queryParams }) => {
    const queryFn = getRoutes({ queryParams });
    const queryKey = ['routes', getQueryKey(queryParams)];
    return useQuery({
        queryFn,
        queryKey,
    });
};

const getListViewMilkScheduleSupplyItem = ({ queryParams }) => {
    const { groupBy } = queryParams;
    // TODO: Group by 'none' (undefined)
    const endpoint = `/schedule-supply-item/list-view/${groupBy}`;
    const params = getParams({
        queryParams,
        dateField: 'start_date',
    });
    return useExpressQuery({ endpoint, params });
};

export const useGetListViewMilkScheduleSupplyItems = ({ queryParams, enabled = true } = {}) => {
    const queryFn = getListViewMilkScheduleSupplyItem({ queryParams });
    const queryKey = ['list-view-supply-items', getQueryKey(queryParams)];
    return useQuery({ queryKey, queryFn, enabled });
};

const getListViewMilkScheduleDemandItem = ({ queryParams }) => {
    const { start, range, processor_id } = queryParams;
    const endpoint = '/schedule-demand-item/list-view/day';
    const params = getParams({
        queryParams: { start, range, processor_id },
        dateField: 'date',
    });
    return useExpressQuery({ endpoint, params });
};

export const useGetListViewMilkScheduleDemandItems = ({ queryParams, enabled = true } = {}) => {
    const queryFn = getListViewMilkScheduleDemandItem({ queryParams });
    const queryKey = ['list-view-demand-items', getQueryKey(queryParams)];
    return useQuery({ queryKey, queryFn, enabled });
};

const getScheduleSupplyItem = ({ queryParams }) => {
    const endpoint = '/schedule-supply-item';
    const params = {
        where: { _id: { inq: queryParams._ids } },
        populate: { processor_id: ['name'], hauling_id: ['name'], route_id: ['name'], route_session_id: ['status'] },
    };
    return useExpressQuery({ endpoint, params });
};

export const useGetScheduleSupplyItem = ({ queryParams, enabled } = {}) => {
    const queryFn = getScheduleSupplyItem({ queryParams });
    const queryKey = ['list-view-supply-items', getQueryKey(queryParams)];
    return useQuery({ queryKey, queryFn, enabled });
};

const getScheduleDemandItem = ({ queryParams }) => {
    const endpoint = '/schedule-demand-item';
    const params = {
        where: { _id: { inq: queryParams._ids } },
        populate: { processor_id: ['name'] },
    };
    return useExpressQuery({ endpoint, params });
};

export const useGetScheduleDemandItem = ({ queryParams, enabled } = {}) => {
    const queryFn = getScheduleDemandItem({ queryParams });
    const queryKey = ['list-view-demand-items', getQueryKey(queryParams)];
    return useQuery({ queryKey, queryFn, enabled });
};

const getListViewProcessorTotals = ({ queryParams }) => {
    const { start, processor_id } = queryParams;
    const { startDate, endDate } = getScheduleDateRange(start, 'week');
    const endpoint = '/schedule-demand-item/list-view-totals/processor';
    const params = getParams({ queryParams: { _id: processor_id } });
    params.where = { ...params.where, start: startDate.toISOString(), end: endDate.toISOString() };
    return useExpressQuery({ endpoint, params });
};

const getListViewHaulerTotals = ({ queryParams }) => {
    const { start, hauling_id, processor_id } = queryParams;
    const { startDate, endDate } = getScheduleDateRange(start, 'week');
    const endpoint = '/schedule-demand-item/list-view-totals/transport';
    const params = getParams({ queryParams: { _id: hauling_id, processor_id } });
    params.where = { ...params.where, start: startDate.toISOString(), end: endDate.toISOString() };
    return useExpressQuery({ endpoint, params });
};

export const useGetListViewTotals = ({ queryParams, enabled = true } = {}) => {
    const processorQueryFn = getListViewProcessorTotals({ queryParams });
    const processorQueryKey = ['list-view-schedule-totals', 'list-view-processor-totals', getQueryKey(queryParams)];
    const haulerQueryFn = getListViewHaulerTotals({ queryParams });
    const haulerQueryKey = ['list-view-schedule-totals', 'list-view-hauler-totals', getQueryKey(queryParams)];
    return useQueries({
        queries: [
            {
                queryKey: processorQueryKey,
                queryFn: processorQueryFn,
                // Disabled if user is processor
                enabled: enabled && !isProcessor(),
            },
            {
                queryKey: haulerQueryKey,
                queryFn: haulerQueryFn,
                // Disabled if user is hauler
                enabled: enabled && !isHauler(),
            },
        ],
    });
};

const getUsers = ({ queryParams }) => {
    const endpoint = '/users';
    // eslint-disable-next-line no-param-reassign
    queryParams.fields = ['name', 'role', 'license_number', 'hauler_number', 'deleted_at'];
    const params = getParams({ queryParams });
    return useExpressQuery({ endpoint, params });
};

const getUserGroups = () => {
    const endpoint = '/user-groups';
    const populate = { users: ['role', 'name', 'deleted_at'] };
    return useExpressQuery({ endpoint, params: { populate } });
};

export const useSelectUsers = ({ queryParams, userGroupsEnabled } = {}) => {
    const userGroupsQueryFn = getUserGroups();
    const userGroupsQueryKey = ['user-groups'];
    const usersQueryFn = getUsers({ queryParams });
    const usersQueryKey = ['users', getQueryKey(queryParams)];
    return useQueries({
        queries: [
            {
                queryKey: userGroupsQueryKey,
                queryFn: userGroupsQueryFn,
                enabled: userGroupsEnabled,
            },
            {
                queryKey: usersQueryKey,
                queryFn: usersQueryFn,
            },
        ],
    });
};

export const useMutateScheduleSupplyItem = (handleSuccess) => {
    const queryClient = useQueryClient();
    const { showSnackbar } = useContext(NotificationContext);
    const { supplySSEStatus } = useScheduleData();
    return useMutation({
        mutationFn: expressMutation,
        onMutate: ({ id, body }) => {
            if (!id) return;
            const previousDataMap = [];

            // iterate over all supply item queries
            queryClient
                .getQueryCache()
                .findAll(['list-view-supply-items'])
                .forEach((query) => {
                    const { queryKey } = query;
                    const oldData = queryClient.getQueryData(queryKey);

                    if (oldData) {
                        // cached data organized per day
                        const newData = oldData.map((row) => {
                            // update item in any day with the corresponding id
                            const updatedItems = row?.items?.map((item) =>
                                item?._id === id
                                    ? {
                                          ...item,
                                          ...body,
                                      }
                                    : item
                            );

                            return {
                                ...row,
                                items: updatedItems,
                            };
                        });

                        // update cache with modified data
                        queryClient.setQueryData(queryKey, newData);
                    }
                });

            // return previous data for potential rollback
            return { previousDataMap };
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['list-view-schedule-totals']);
            if (supplySSEStatus !== 'connected') {
                queryClient.invalidateQueries(['list-view-supply-items']);
            }
            if (handleSuccess) {
                handleSuccess({ success: true });
            } else {
                showSnackbar({ severity: 'success', message: 'Success' });
            }
        },
        onError: () => {
            showSnackbar({ severity: 'error', message: errorMessage });
        },
    });
};

export const useMutateScheduleDemandItem = () => {
    const queryClient = useQueryClient();
    const { showSnackbar } = useContext(NotificationContext);
    const { demandSSEStatus } = useScheduleData();
    return useMutation({
        mutationFn: expressMutation,
        onMutate: ({ id, body }) => {
            if (!id) return;
            const previousDataMap = [];

            // iterate over all supply item queries
            queryClient
                .getQueryCache()
                .findAll(['list-view-demand-items'])
                .forEach((query) => {
                    const { queryKey } = query;
                    const oldData = queryClient.getQueryData(queryKey);

                    if (oldData) {
                        // cached data organized per day
                        const newData = oldData.map((row) => {
                            // update item in any day with the corresponding id
                            const updatedItems = row?.items?.map((item) =>
                                item?._id === id
                                    ? {
                                          ...item,
                                          ...body,
                                      }
                                    : item
                            );

                            return {
                                ...row,
                                items: updatedItems,
                            };
                        });

                        // update cache with modified data
                        queryClient.setQueryData(queryKey, newData);
                    }
                });

            // return previous data for potential rollback
            return { previousDataMap };
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['list-view-schedule-totals']);
            if (demandSSEStatus !== 'connected') {
                queryClient.invalidateQueries(['list-view-demand-items']);
            }
            showSnackbar({ severity: 'success', message: 'Success' });
        },
        onError: () => {
            showSnackbar({ severity: 'error', message: errorMessage });
        },
    });
};

/**
 * @param {Parameters<typeof getSendScheduleQuery>[0]} data
 */
const sendSchedule = (data) => {
    return expressMutation({ method: 'PUT', options: { url: getSendScheduleQuery(data) } });
};

export const useMutateSendSchedule = () => {
    return useMutation({ mutationFn: sendSchedule });
};
