import Region from '../../../constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [Region.DEMO, Region.BONGARDS, Region.UNC],
});

const demo = new Environment({
    name: stages.DEMO,
    regions: [Region.DEMO],
});

const staging = new Environment({
    name: stages.STAGING,
    regions: [Region.UNC, Region.BONGARDS],
});

const local = new Environment({
    name: stages.LOCAL,
    regions: [Region.UNC, Region.DEMO, Region.BONGARDS],
});

const dev = new Environment({
    name: stages.DEV,
    regions: [Region.UNC],
});

const can_use_payment_line_item_configs = new MockSplit({
    name: 'can_use_payment_line_item_configs',
    environments: [local, dev, staging, demo, prod],
});

export default can_use_payment_line_item_configs;
