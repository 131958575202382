import React, { createContext, useContext, useState } from 'react';

const ScheduleSelectionContext = createContext({});

export function ScheduleSelectionProvider({ children }) {
    const [selectedItems, setSelectedItems] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [view, setView] = useState({
        editItem: undefined,
        highlightRows: new Set(),
        viewItem: undefined,
        deleteItem: undefined,
        showEditDialog: false,
        showBulkEditDialog: false,
        showItemHistoryDialog: false,
        showDeleteDialog: false,
        showCopyDialog: undefined,
    });

    const flashRows = (rowIds) => {
        if (!rowIds) return;
        setView((prev) => {
            const newHighlightRows = new Set(prev.highlightRows);
            rowIds.forEach((rowId) => newHighlightRows.add(rowId));
            return { ...prev, highlightRows: newHighlightRows };
        });

        setTimeout(() => {
            setView((prev) => {
                const newHighlightRows = new Set(prev.highlightRows);
                rowIds.forEach((rowId) => newHighlightRows.delete(rowId));
                return { ...prev, highlightRows: newHighlightRows };
            });
        }, 3000);
    };

    return (
        <ScheduleSelectionContext.Provider
            value={{
                selectedItems,
                setSelectedItems,
                selectedRows,
                setSelectedRows,
                view,
                setView,
                flashRows,
            }}
        >
            {children}
        </ScheduleSelectionContext.Provider>
    );
}

export const useScheduleSelection = () => {
    const context = useContext(ScheduleSelectionContext);
    if (context === undefined) {
        throw new Error('useScheduleSelection must be used within a ScheduleSelectionProvider');
    }
    return context;
};
