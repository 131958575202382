import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const uploadClearedPaymentStatus = async (formData) => {
    try {
        const response = await axios.post(`${getExpressAPIHost()}/payment-status/import-cleared-payment-status`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response;
    } catch (err) {
        if (err?.response?.data?.statusCode === 422) {
            throw new Error(err?.response?.data?.message);
        }
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('There was a problem uploading your file. Please contact support.');
    }
};
export default uploadClearedPaymentStatus;
