import axios from 'axios';
import { getMerchandisePurchaseQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const getMerchandisePurchaseDetails = async ({ id, startDate, endDate, purchasedBy }) => {
    try {
        const merchandiseQuery = getMerchandisePurchaseQuery({ id, fields: ['purchased_by', 'merchandise_item', 'purchase_type', 'purchase_quantity', 'purchase_amount', 'amount_paid', 'outstanding_balance', 'partner_total', 'purchased_date'], startDate, endDate, purchasedBy, queryBuilder: buildExpressQuery });
        const merchandiseResponse = await axios.get(merchandiseQuery);
        const merchandise = merchandiseResponse.data;
        return { merchandise };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getMerchandisePurchaseDetails;
