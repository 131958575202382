import { useState, useRef, useCallback } from 'react';

const useBatching = (interval) => {
    const [batchedItems, setBatchedItems] = useState([]);
    const bufferRef = useRef([]);
    const timeoutRef = useRef(null);

    const addItems = useCallback(
        (items) => {
            bufferRef.current = [...bufferRef.current, ...items];

            if (!timeoutRef.current) {
                timeoutRef.current = setTimeout(() => {
                    setBatchedItems(bufferRef.current);
                    bufferRef.current = [];
                    timeoutRef.current = null;
                }, interval);
            }
        },
        [interval]
    );

    return { batchedItems, addItems };
};

export default useBatching;
