import { useContext } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { NotificationContext } from '../../../NotificationContext';
import useExpress, { expressMutation } from '../../../useExpress';
import { useScheduleData } from '../../../main/apps/FunctionalApps/schedule-list-view/ScheduleDataContext';

const getUsersQuery = ({ where, ...rest }) => {
    const params = {
        where: {
            deleted_at: { eq: null },
            ...where,
        },
        ...rest,
    };
    const endpoint = '/users';
    return useExpress({ endpoint, params });
};

export const useFetchUsers = ({ enabled = true, ...queryParams } = {}) => {
    const queryFn = getUsersQuery(queryParams);
    const queryKey = ['user-data', queryParams];
    return useQuery({ queryKey, queryFn, enabled });
};

export const useMutateScheduleSupplyCell = () => {
    const queryClient = useQueryClient();
    const { showSnackbar } = useContext(NotificationContext);
    const { supplySSEStatus } = useScheduleData();

    return useMutation({
        mutationFn: expressMutation,
        onMutate: ({ id, body }) => {
            const previousDataMap = [];

            // iterate over all supply item queries
            queryClient
                .getQueryCache()
                .findAll(['list-view-supply-items'])
                .forEach((query) => {
                    const { queryKey } = query;
                    const oldData = queryClient.getQueryData(queryKey);

                    if (oldData) {
                        // store data before updating for rollback
                        previousDataMap.push({ queryKey, oldData });

                        // create a new optimistic version of the cached data
                        const newData = oldData.map((row) => {
                            const updatedItems = row?.items?.map((item) =>
                                item?._id === id
                                    ? {
                                          ...item,
                                          ...body,
                                      }
                                    : item
                            );

                            return {
                                ...row,
                                items: updatedItems,
                            };
                        });

                        // apply optimistic update to cache
                        queryClient.setQueryData(queryKey, newData);
                    }
                });

            // return previous state to onError for rollback
            return { previousDataMap };
        },
        onSuccess: (response) => {
            if (supplySSEStatus !== 'connected') {
                const id = response[0];

                // invalidate only queries containing the updated row
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        if (query.queryKey[0] === 'list-view-supply-items') {
                            const cachedData = queryClient.getQueryData(query.queryKey);

                            return cachedData?.some((row) => row?.items?.some((item) => item?._id === id));
                        }
                        return false;
                    },
                });
                queryClient.invalidateQueries(['list-view-schedule-totals']);
            }

            showSnackbar({ severity: 'success', message: 'Success' });
        },
        onError: (_error, _variables, context) => {
            // restore previous data from rollback snapshot
            if (context?.previousDataMap) {
                context.previousDataMap.forEach(({ queryKey, oldData }) => {
                    queryClient.setQueryData(queryKey, oldData);
                });
            }

            showSnackbar({ severity: 'error', message: 'Failed to update the schedule supply item. Changes reverted.' });
        },
    });
};
