import React, { useState, useEffect } from 'react';
import { Popover, Paper, TextField, Box, Button } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import configs from './configs';

const PopupEditTextFieldCell = (props) => {
    const { id, field, value: cellValue, row, table, inputProps = {}, hasFocus } = props;
    const { valueAsNumber } = inputProps;
    const apiRef = useGridApiContext();
    const [anchorEl, setAnchorEl] = useState(null);

    const mutateConfig = configs[table];
    const { mutateAsync } = mutateConfig.useHook();

    useEffect(() => {
        if (hasFocus) {
            setAnchorEl(props.api.getCellElement(id, field));
        }
    }, [hasFocus]);

    const handleClose = () => {
        apiRef.current.setCellMode(id, field, 'view');
        setAnchorEl(null);
    };

    const handleMutate = (value) => {
        let valueCopy = value;

        if (valueCopy?.length === 0) {
            valueCopy = null;
        } else if (valueAsNumber) {
            valueCopy = parseFloat(valueCopy);
        }

        const extractIdFields = mutateConfig.schema.meta().extractIdFields || [];
        const formattedRow = {
            ...row,
            [field]: valueCopy,
        };

        extractIdFields.forEach((_field) => {
            if (formattedRow[_field] && formattedRow[_field]._id) {
                formattedRow[_field] = formattedRow[_field]._id;
            }
        });

        const body = mutateConfig.schema.validateSync(formattedRow, { stripUnknown: true });

        mutateConfig.handleMutate({ id, body }, { mutate: mutateAsync });
        return apiRef.current.setCellMode(id, field, 'view');
    };

    const triggerMutate = () => {
        handleMutate(cellValue);
    };

    const handleClear = () => {
        apiRef.current.setEditCellValue({
            id,
            field,
            value: '',
        });
    };

    const handleChange = (event) => {
        const { value } = event.target;

        apiRef.current.setEditCellValue({
            id,
            field,
            value,
        });
    };

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            // wrap
        >
            <Paper
                sx={{
                    p: 2,
                    width: '374px',
                    height: 'auto',
                    // wrap
                }}
            >
                <Box display="flex" flexDirection="column">
                    <TextField
                        variant="outlined"
                        multiline
                        value={cellValue}
                        onChange={handleChange}
                        onKeyDown={({ key }) => {
                            if (key === 'Enter') triggerMutate();
                        }}
                        type={valueAsNumber ? 'number' : 'text'}
                        fullWidth
                        inputProps={{ maxLength: 255, ...inputProps }}
                        sx={{
                            paddingBottom: 2,
                        }}
                    />
                    <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        gap={1.5}
                        // wrap
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClear}
                            // wrap
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={triggerMutate}
                            // wrap
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Popover>
    );
};

export default PopupEditTextFieldCell;
