import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MTableBody, MTableToolbar } from '@material-table/core';
import _ from 'lodash';
import DataTableFilterExtension from '../custom-components/DataTableFilterExtension';
import CustomizableDataTable from './CustomizableDataTable';
import { buildExportButtons } from '../../utils';
import DataTableDialogDeprecated from './dialogs/DataTableDialog.deprecated';
import DataTableDialog from './dialogs/DataTableDialog';

const ActionToolbar = ({ actions, history }) => {
    const ActionButton = ({ action, iconOverride }) => {
        const { title, icon, disable, onClick, redirectURL } = action;

        const handleClick = useCallback(() => {
            if (redirectURL) {
                history.push({ pathname: redirectURL });
            } else {
                onClick();
            }
        }, [redirectURL, onClick]);

        return (
            <Grid item>
                <Button variant="contained" color="secondary" endIcon={iconOverride ?? icon} disabled={disable} onClick={handleClick} style={{ marginLeft: 10 }}>
                    {title}
                </Button>
            </Grid>
        );
    };

    return (
        <Grid container style={{ paddingRight: 10, paddingTop: 10 }}>
            {actions.toggle && (
                <Grid item>
                    <Tooltip title={actions.toggle.title}>
                        <FormControlLabel control={<Switch checked={actions.toggle.toggle} onChange={actions.toggle.onToggle} />} label={actions.toggle.title} labelPlacement="start" />
                    </Tooltip>
                </Grid>
            )}
            {actions.secondaryToggle && (
                <Grid item>
                    <Tooltip title={actions.secondaryToggle.title}>
                        <FormControlLabel control={<Switch checked={actions.secondaryToggle.toggle} onChange={actions.secondaryToggle.onToggle} />} label={actions.secondaryToggle.title} labelPlacement="start" />
                    </Tooltip>
                </Grid>
            )}
            <Grid item style={{ flex: 1 }} />
            {actions.addSecondary && <ActionButton action={actions.addSecondary} iconOverride={<AddIcon />} />}
            {actions.add && <ActionButton action={actions.add} iconOverride={<AddIcon />} />}
            {actions.genericClickable && <ActionButton action={actions.genericClickable} />}
        </Grid>
    );
};

const Footer = ({ label, value }) => (
    <tfoot>
        <tr>
            <td colSpan={100} style={{ paddingTop: 20 }}>
                <Box display="flex" justifyContent="center" alignItems="center" gridGap={12}>
                    <Typography variant="body1">{label}</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {value}
                    </Typography>
                </Box>
            </td>
        </tr>
    </tfoot>
);

// eslint-disable-next-line
const buildTableComponents = (actions, history, footer) => ({
    Body: (props) => (
        // eslint-disable-next-line
        <React.Fragment>
            <MTableBody {...props} />
            {footer && <Footer label={footer.label} value={footer.value} />}
        </React.Fragment>
    ),
    Toolbar: (props) => (
        <div>
            <MTableToolbar {...props} />
            {(actions.add || actions.toggle || actions.genericClickable) && <ActionToolbar actions={actions} history={history} />}
        </div>
    ),
});

const hasDialogActions = (actions) =>
    actions.edit ||
    actions.editManifest ||
    actions.delete ||
    actions.inspect ||
    actions.inspectRouteSession ||
    actions.slip ||
    actions.login ||
    actions.report ||
    actions.download ||
    actions.pooled ||
    actions.inspectPickup ||
    actions.inspectLabReport ||
    actions.inspectPFTransfer ||
    actions.editModal ||
    actions.editItemModal ||
    actions.inspectItemModal ||
    actions.viewDeliveryReport ||
    actions.inspectPFRouteSession ||
    actions.editPFRouteSession ||
    actions.editPFTransfer ||
    actions.printBarcodeQueue ||
    actions.cancelBarcodeQueue ||
    actions.completeIPDOModal ||
    actions.editIPDOModal ||
    (actions.dialogOptions && actions.dialogOptions.length > 0);

const hasFilterExtension = (columns) => !_.isEmpty(columns.filter((col) => col.filter));

const buildTableActions = (dialog, actions, setOpenDialog, setRowData) =>
    dialog || hasDialogActions(actions)
        ? [
              {
                  icon: 'more_vert',
                  iconProps: { 'data-testid': 'table-kebab' },
                  tooltip: '',
                  onClick: (event, rowData) => {
                      setRowData(rowData);
                      setOpenDialog(true);
                  },
              },
          ]
        : [];

const handleClose = (setOpenDialog) => setOpenDialog(false);

const handleFetch = (forceFetch, setOpenDialog) => {
    forceFetch();
    setOpenDialog(false);
};

const handleOnWheel = (e, el) => {
    // eslint-disable-next-line no-param-reassign
    el.scrollTop += 3 * e.deltaY;
};

const buildTableOptions = (options, columns, data) => {
    const optionsWithExportMenu = { ...options, exportMenu: buildExportButtons(options.exportFileName, columns, data) };
    return { ...(options.exportFileName ? optionsWithExportMenu : options), pageSize: options.pageSize };
};

const buildTableLocalization = () => ({ header: { actions: '' } });

const DataTable = ({ name, columns, data, title, actions, options, detailPanel, history, forceFetch, dialog, footer, isLoading, onSelectionChange, isInlineFilters = false, components, useEditId, useViewId, useNewActionDialog = false }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [rowData, setRowData] = useState({});
    const [contentEl, setContentEl] = useState();
    const [filteredData, setFilteredData] = useState(data);
    const [filter, setFilter] = useState({});
    const testId = title ? `${title.replace(/ /g, '')}Table` : 'NoTitleTable';

    useEffect(() => {
        setContentEl(document.querySelector('div[class*="scrollable-"]'));
    }, []);

    const filterTableData = (filters) => {
        if (!_.isEmpty(filters)) {
            setFilter(filters);
            const newData = data.filter((v) => {
                let include = true;
                Object.keys(filters).forEach((key) => {
                    if (!(v[key] !== null && v[key] !== undefined && filters[key].formatter(v[key]) === filters[key].value)) {
                        include = false;
                    }
                });
                return include;
            });

            setFilteredData(newData);
        } else {
            setFilter({});
            setFilteredData(data);
        }
    };

    useEffect(() => {
        filterTableData(filter);
    }, [data]);

    const dialogComponent = useNewActionDialog ? (
        <DataTableDialog
            open={openDialog}
            onClose={() => handleClose(setOpenDialog)}
            actions={actions}
            rowData={rowData}
            forceFetch={() => handleFetch(forceFetch, setOpenDialog)}
            // wrap
        />
    ) : (
        <DataTableDialogDeprecated
            open={openDialog}
            onClose={() => handleClose(setOpenDialog)}
            actions={actions}
            rowData={rowData}
            forceFetch={() => handleFetch(forceFetch, setOpenDialog)}
            useEditId={useEditId}
            useViewId={useViewId}
            // wrap
        />
    );

    return (
        <div data-testid={testId}>
            {hasFilterExtension(columns) && <DataTableFilterExtension title={`${title} Table Filters:`} columns={columns} data={data} filterTableData={filterTableData} />}
            <div onWheel={(e) => handleOnWheel(e, contentEl)}>
                {!!dialog &&
                    React.cloneElement(dialog, {
                        handleClose: () => handleClose(setOpenDialog),
                        open: openDialog,
                        rowData,
                    })}
                {dialogComponent}
                <CustomizableDataTable
                    name={name || title}
                    columns={columns}
                    data={isInlineFilters ? data : filteredData}
                    title={title}
                    components={components || buildTableComponents(actions, history, footer)}
                    actions={buildTableActions(dialog, actions, setOpenDialog, setRowData)}
                    options={buildTableOptions(options, columns, data)}
                    detailPanel={detailPanel}
                    localization={buildTableLocalization()}
                    isLoading={isLoading}
                    onSelectionChange={onSelectionChange}
                    isInlineFilters={isInlineFilters}
                />
            </div>
        </div>
    );
};

DataTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            field: PropTypes.string.isRequired,
            type: PropTypes.string,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        add: PropTypes.shape({ title: PropTypes.string.isRequired, redirectURL: PropTypes.string, onClick: PropTypes.func }),
        addSecondary: PropTypes.shape({ title: PropTypes.string.isRequired, redirectURL: PropTypes.string.isRequired }),
        edit: PropTypes.shape({ title: PropTypes.string.isRequired, redirectURL: PropTypes.string.isRequired }),
        editManifest: PropTypes.shape({ title: PropTypes.string.isRequired, redirectURL: PropTypes.string.isRequired }),
        delete: PropTypes.shape({ title: PropTypes.string.isRequired, dataType: PropTypes.string.isRequired }),
        inspect: PropTypes.shape({ title: PropTypes.string.isRequired, redirectURL: PropTypes.string.isRequired }),
        inspectRouteSession: PropTypes.shape({ title: PropTypes.string.isRequired, redirectURL: PropTypes.string.isRequired }),
        slip: PropTypes.shape({ type: PropTypes.string }),
        login: PropTypes.shape({ title: PropTypes.string.isRequired, handler: PropTypes.func.isRequired }),
        report: PropTypes.bool,
        pooled: PropTypes.bool,
        genericClickable: PropTypes.shape({ title: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired }),
        dialogOptions: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string.isRequired, setOpen: PropTypes.func.isRequired, setSelected: PropTypes.func.isRequired })),
    }),
    options: PropTypes.shape({ pageSize: PropTypes.number, exportCsv: PropTypes.func }),
    forceFetch: PropTypes.func,
    isLoading: PropTypes.bool,
};

DataTable.defaultProps = {
    actions: {},
    options: { pageSize: 25 },
    forceFetch: () => {},
    isLoading: false,
};

export default withRouter(DataTable);
