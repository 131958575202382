import React from 'react';
import { point } from '@turf/helpers';
import distance from '@turf/distance';

/**
 * Offsets map drawings that are within close proximity to each other to prevent marker overlap.
 *
 * @param {React.ReactElement[]} drawings - Array of marker components that may contain overlapping coordinates.
 * @param {number} thresholdMeters - Distance threshold (in meters) to consider two points as overlapping.
 * @param {number} offsetDegrees - Amount to offset (in degrees) per overlap. ~0.00005 ≈ ~5 meters.
 */
export const offsetCloseDrawings = (drawings, thresholdMeters = 5, offsetDegrees = 0.00005) => {
    const processedPoints = [];
    const updated = [];

    drawings.forEach((drawing) => {
        const rawLat = drawing?.props?.shape?.center?.lat || drawing?.props?.event?.latitude || null;
        const rawLng = drawing?.props?.shape?.center?.lng || drawing?.props?.event?.longitude || null;

        const lat = parseFloat(rawLat);
        const lng = parseFloat(rawLng);

        if (!lat || !lng) {
            updated.push(drawing);
            return;
        }

        const currentPoint = point([lng, lat]);
        let overlapCount = 0;

        // compare to all previously processed points to detect proximity
        processedPoints.forEach((seenPoint) => {
            const dist = distance(currentPoint, point([seenPoint.lng, seenPoint.lat]), {
                units: 'meters',
            });

            if (dist <= thresholdMeters) {
                // count how many previous points are too close
                overlapCount += 1;
            }
        });

        processedPoints.push({ lat, lng });

        // if overlap exists, calculate an offset using angle-based fan-out
        const angle = overlapCount * 45 * (Math.PI / 180);
        const offsetLat = overlapCount ? lat + Math.sin(angle) * offsetDegrees : lat;
        const offsetLng = overlapCount ? lng + Math.cos(angle) * offsetDegrees : lng;

        // create a cloned version with modified coordinates
        const updatedDrawing = React.cloneElement(drawing, {
            ...drawing.props,
            shape: drawing.props.shape
                ? {
                      ...drawing.props.shape,
                      center: {
                          ...drawing.props.shape.center,
                          lat: offsetLat,
                          lng: offsetLng,
                      },
                  }
                : undefined,
            event: drawing.props.event
                ? {
                      ...drawing.props.event,
                      latitude: offsetLat,
                      longitude: offsetLng,
                  }
                : undefined,
        });

        updated.push(updatedDrawing);
    });

    return updated;
};

export const getZoomFromRadius = (radius) => {
    if (radius < 200) {
        return 16;
    }
    if (radius <= 1000) {
        return 15;
    }
    if (radius <= 3500) {
        return 13;
    }
    if (radius <= 8500) {
        return 12;
    }
    if (radius <= 17000) {
        return 11;
    }
    if (radius <= 25000) {
        return 10;
    }

    // this comfortably fits 120,000m radius
    return 8;
};
