export const getScheduleDateRange = (start, range) => {
    if (!start || !range) {
        // DEBUG
        // eslint-disable-next-line no-console
        console.warn('⚠️ Warning: getScheduleDateRange received invalid arguments');
        return {};
    }

    const startDate = start.clone().startOf('day');
    const endDate = range === 'day' ? start.clone().endOf(range) : start.clone().add(6, 'days').endOf('day');

    return { startDate, endDate };
};

export const idString = (id) => (typeof id === 'string' ? id : null);

export const getRowId = (row) => {
    return row._id?._id ?? idString(row._id) ?? 'unassigned';
};

export const getIdsArray = (selectedItems) => {
    return Object.keys(selectedItems).reduce((acc, key) => {
        acc.push(...Array.from(selectedItems[key]));
        return acc;
    }, []);
};

export const updateCacheRowById = async ({ queryClient, queryKeyTag, fetch, documentIds }) => {
    const { data } = await fetch();
    if (!data) {
        // DEBUG
        // eslint-disable-next-line no-console
        console.warn('⚠️ Warning: updateCacheRowById received no data from fetch');
        return;
    }

    const isDeleted = !data.length;
    let updatedItem;
    const existingQueries = queryClient.getQueriesData({ queryKey: [queryKeyTag] });

    existingQueries.forEach(([queryKey, queryData]) => {
        if (!queryData) {
            // DEBUG
            // eslint-disable-next-line no-console
            console.warn('⚠️ Warning: updateCacheRowById received no data for queryKey:', queryKey);
            return;
        }

        const newData = queryData.map((row) => {
            if (!row.items) return row;
            let updatedItems = [];
            if (isDeleted) {
                updatedItems = row.items.filter((item) => !documentIds.includes(item?._id));
            } else {
                updatedItems = row.items.map((item) => {
                    const newItem = data.find((d) => d._id === item?._id);
                    if (newItem) {
                        updatedItem = { ...newItem };
                        return { ...newItem };
                    }
                    return item;
                });
            }
            return {
                ...row,
                items: updatedItems,
            };
        });

        queryClient.setQueryData(queryKey, newData);
    });

    return updatedItem;
};

export const resetFilters = {
    order: 'start_date ASC',
    search: {
        'route_id.name': '',
    },
    notes: undefined,
    processor_id: [],
    hauling_id: [],
    milk_category: [],
};
