import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const ConfirmationDialog = ({ open, message, onDialogConfirmAction, onDialogCancelAction }) => (
    <Dialog open={open}>
        <DialogTitle>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">Warning</Typography>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Typography variant="body1">{message}</Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={onDialogConfirmAction}>
                Yes
            </Button>
            <Button variant="contained" onClick={onDialogCancelAction}>
                No
            </Button>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
