import React, { useState } from 'react';
import moment from 'moment';
import { FormControl } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import configs from './configs';
import LocalizedDatePicker from '../../../custom-widgets/LocalizedDatePicker';

const EditDateTimeCell = (props) => {
    const { id, field, value: cellValue, row, table, ampm = true } = props;
    const apiRef = useGridApiContext();

    const mutateConfig = configs[table];
    const { mutateAsync } = mutateConfig.useHook();
    const [textFieldValue, setTextFieldValue] = useState();

    const handleMutate = (value) => {
        const extractIdFields = mutateConfig.schema.meta().extractIdFields || [];
        const formattedRow = {
            ...row,
            [field]: value.toISOString(),
        };

        extractIdFields.forEach((_field) => {
            if (formattedRow[_field] && formattedRow[_field]._id) {
                formattedRow[_field] = formattedRow[_field]._id;
            }
        });

        const body = mutateConfig.schema.validateSync(formattedRow, { stripUnknown: true });

        apiRef.current.setEditCellValue({
            id,
            field,
            value,
        });

        return mutateConfig.handleMutate({ id, body }, { mutate: mutateAsync });
    };

    const handleChange = (event) => {
        setTextFieldValue(event);
    };

    const triggerMutate = () => {
        handleMutate(textFieldValue);
    };

    const handleAccept = (value) => {
        handleMutate(value);
        apiRef.current.setCellMode(id, field, 'view');
    };

    return (
        <FormControl
            fullWidth
            variant="outlined"
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                },
            }}
        >
            <LocalizedDatePicker
                value={moment(cellValue)}
                onAccept={handleAccept}
                onChange={handleChange}
                ampm={ampm}
                slotProps={{
                    textField: {
                        onKeyDown: (event) => {
                            event.persist();
                            if (event.key === 'Enter') triggerMutate();
                        },
                    },
                }}
                // wrap
            />
        </FormControl>
    );
};

export default EditDateTimeCell;
