import React from 'react';
import { TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import configs from './configs';

const EditTextFieldCell = (props) => {
    const { id, field, value: cellValue, row, table, inputProps = {}, valueAsNumber } = props;
    const apiRef = useGridApiContext();

    const mutateConfig = configs[table];
    const { mutateAsync } = mutateConfig.useHook();

    const handleMutate = (value) => {
        const extractIdFields = mutateConfig.schema.meta().extractIdFields || [];
        const formattedRow = {
            ...row,
            [field]: valueAsNumber ? parseFloat(value) : value,
        };

        extractIdFields.forEach((_field) => {
            if (formattedRow[_field] && formattedRow[_field]._id) {
                formattedRow[_field] = formattedRow[_field]._id;
            }
        });

        const body = mutateConfig.schema.validateSync(formattedRow, { stripUnknown: true });
        return mutateConfig.handleMutate({ id, body }, { mutate: mutateAsync });
    };

    const triggerMutate = () => {
        handleMutate(cellValue);
    };

    const handleChange = (event) => {
        const { value } = event.target;

        apiRef.current.setEditCellValue({
            id,
            field,
            value,
        });
    };

    return (
        <TextField
            variant="outlined"
            value={cellValue}
            onChange={handleChange}
            onBlur={() => apiRef.current.setCellMode(id, field, 'view')}
            onKeyDown={(event) => {
                event.persist();
                if (event.key === 'Enter') triggerMutate();
            }}
            type={valueAsNumber ? 'number' : 'text'}
            inputProps={inputProps}
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                },
            }}
        />
    );
};

export default EditTextFieldCell;
