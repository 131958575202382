import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';
import store from '../../../../store';

export const EditAdminConfig = { auth: authRoles.admin, routes: [{ path: '/edit-admin', component: FuseLoadable({ loader: () => import('./EditAdminApp') }) }] };

export const EditSubAdminConfig = { auth: authRoles.admin, routes: [{ path: '/edit-sub-user', component: FuseLoadable({ loader: () => import('./EditSubAdminApp') }) }] };

export const EditDriverConfig = { auth: authRoles.hauling, routes: [{ path: '/edit-driver', component: FuseLoadable({ loader: () => import('./EditDriverApp') }) }] };

export const EditReceiverConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/edit-receiver', component: FuseLoadable({ loader: () => import('./EditReceiverApp') }) }] };

export const EditHaulingConfig = { auth: authRoles.admin, routes: [{ path: '/edit-hauling/:id', component: FuseLoadable({ loader: () => import('../../../../custom-components/forms/hauling-companies/form/RegionRouter') }) }] };

export const EditSubHaulingConfig = { auth: authRoles.hauling, routes: [{ path: '/edit-sub-hauling', component: FuseLoadable({ loader: () => import('./EditSubHaulingApp') }) }] };

export const EditProcessorConfig = { auth: authRoles.admin, routes: [{ path: '/edit-processor/:id', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/processors/form/RegionRouter') }) }] };

export const EditSubProcessorConfig = { auth: authRoles.processor, routes: [{ path: '/edit-sub-processor', component: FuseLoadable({ loader: () => import('./EditSubProcessorApp') }) }] };

export const EditProducerConfig = { auth: authRoles.admin, routes: [{ path: '/edit-producer', component: FuseLoadable({ loader: () => import('./EditProducerApp') }) }] };

export const EditProducerBusinessConfig = { auth: authRoles.admin, routes: [{ path: '/edit-producer-business/:id', component: FuseLoadable({ loader: () => import('../../../../custom-components/forms/producer-business/form/RegionRouter') }) }] };

export const EditSubProducerConfig = { auth: authRoles.producer, routes: [{ path: '/edit-sub-producer', component: FuseLoadable({ loader: () => import('./EditSubProducerApp') }) }] };

export const EditTrailerConfig = { auth: [...authRoles.admin, ...authRoles.hauling], routes: [{ path: '/edit-trailer', component: FuseLoadable({ loader: () => import('./EditTrailerApp') }) }] };

export const EditTruckConfig = { auth: [...authRoles.admin, ...authRoles.hauling], routes: [{ path: '/edit-truck', component: FuseLoadable({ loader: () => import('./EditTruckApp') }) }] };

export const EditRouteConfig = { auth: authRoles.admin, routes: [{ path: '/edit-route', component: FuseLoadable({ loader: () => import('./EditRouteApp') }) }] };

export const EditScheduleRouteConfig = { auth: authRoles.hauling, routes: [{ path: '/edit-schedule-route', component: FuseLoadable({ loader: () => import('./EditScheduleRouteApp') }) }] };

export const EditSealConfig = { auth: authRoles.everyoneButGuest, routes: [{ path: '/edit-seal/:routeSessionId', component: FuseLoadable({ loader: () => import('./EditSealApp') }) }] };

export const CompleteIPDOConfig = { auth: authRoles.admin, routes: [{ path: '/complete-ipdo/:id?', component: FuseLoadable({ loader: () => import('../ipdo/complete/CompleteIPDOApp') }) }] };

export const EditIPDOConfig = { auth: authRoles.admin, routes: [{ path: '/edit-ipdo/:id?', component: FuseLoadable({ loader: () => import('../ipdo/EditIPDOApp') }) }] };

export const EditSiloConfig = { auth: authRoles.processor, routes: [{ path: '/edit-silo', component: FuseLoadable({ loader: () => import('./EditSiloApp') }) }] };

export const EditPickupConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/edit-pickup/:id?', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/pickups-raw-milk/form/MutatePickup') }) }] };

export const EditDropoffConfig = { auth: authRoles.everyoneButGuest, routes: [{ path: '/edit-dropoff/:id?', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/dropoffs-raw-milk/form/MutateDropoff') }) }] };

export const EditRouteSessionConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/edit-route-session/:id', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/route-sessions-raw-milk/form/MutateRouteSession') }) }] };

export const EditLabReportConfig = { auth: authRoles.admin, routes: [{ path: '/edit-lab-report/:id', component: FuseLoadable({ loader: () => import('../../../../custom-components/forms/lab-report/form/RegionRouter') }) }] };

export const EditBaseTransferConfig = { auth: authRoles.admin, routes: [{ path: '/edit-base-transfer', component: FuseLoadable({ loader: () => import('./EditBaseTransferApp') }) }] };

export const EditMerchandiseConfig = { auth: authRoles.admin, routes: [{ path: '/edit-merchandise/:id', component: FuseLoadable({ loader: () => import('./EditMerchandiseApp') }) }] };

export const EditMerchandisePurchaseConfig = { auth: authRoles.admin, routes: [{ path: '/edit-merchandise-purchase/:id', component: FuseLoadable({ loader: () => import('./EditMerchandisePurchaseApp') }) }] };

export const EditAssigneeConfig = { auth: authRoles.admin, routes: [{ path: '/edit-assignee', component: FuseLoadable({ loader: () => import('./EditAssigneeApp') }) }] };

export const EditAssignmentConfig = { auth: authRoles.admin, routes: [{ path: '/edit-assignment', component: FuseLoadable({ loader: () => import('./EditAssignmentApp') }) }] };

export const EditDeferralConfig = { auth: authRoles.admin, routes: [{ path: '/edit-deferral', component: FuseLoadable({ loader: () => import('./EditDeferralApp') }) }] };

export const EditWashFacilityConfig = { auth: authRoles.admin, routes: [{ path: '/edit-wash-facility', component: FuseLoadable({ loader: () => import('./EditWashFacilityApp') }) }] };

export const EditLabProviderConfig = { auth: authRoles.admin, routes: [{ path: '/edit-lab-provider', component: FuseLoadable({ loader: () => import('./EditLabProviderApp') }) }] };

export const EditDropoffSampleConfig = { auth: authRoles.admin, routes: [{ path: '/edit-dropoff-sample', component: FuseLoadable({ loader: () => import('./EditDropoffSampleApp') }) }] };

export const EditSplitProducerConfig = { auth: authRoles.admin, routes: [{ path: '/edit-split-producer', component: FuseLoadable({ loader: () => import('./EditSplitProducerApp') }) }] };

export const EditHaulingContractConfig = { auth: authRoles.admin, routes: [{ path: '/edit-hauling-contract', component: FuseLoadable({ loader: () => import('./EditHaulingContract') }) }] };

export const EditHaulerRateConfig = { auth: authRoles.admin, routes: [{ path: '/edit-hauler-rate', component: FuseLoadable({ loader: () => import('./EditHaulerRate') }) }] };

export const EditSupplementalHaulingContractConfig = { auth: authRoles.admin, routes: [{ path: '/edit-supplemental-hauling-contract', component: FuseLoadable({ loader: () => import('./EditSupplementalHaulingContract') }) }] };

export const EditEffectiveHaulerRateConfig = { auth: authRoles.admin, routes: [{ path: '/edit-effective-rate', component: FuseLoadable({ loader: () => import('./EditEffectiveHaulerRateApp') }) }] };

export const EditPaymentStatusConfig = { auth: authRoles.admin, routes: [{ path: '/edit-payment-status', component: FuseLoadable({ loader: () => import('./EditPaymentStatusApp') }) }] };

export const EditAdhocPayConfig = { auth: authRoles.admin, routes: [{ path: '/edit-adhoc-pay/:id', component: FuseLoadable({ loader: () => import('../../../../custom-components/pay-reports/adhoc-pay/MutateAdhocPayApp') }) }] };

export default [
    EditMerchandiseConfig,
    EditMerchandisePurchaseConfig,
    EditAssigneeConfig,
    EditAssignmentConfig,
    EditBaseTransferConfig,
    EditAdminConfig,
    EditDeferralConfig,
    EditSubAdminConfig,
    EditDriverConfig,
    EditReceiverConfig,
    EditHaulingConfig,
    EditSubHaulingConfig,
    EditProcessorConfig,
    EditSubProcessorConfig,
    EditProducerConfig,
    EditProducerBusinessConfig,
    EditSubProducerConfig,
    EditTrailerConfig,
    EditTruckConfig,
    EditRouteConfig,
    EditScheduleRouteConfig,
    EditSealConfig,
    CompleteIPDOConfig,
    EditIPDOConfig,
    EditSiloConfig,
    EditPickupConfig,
    EditDropoffConfig,
    EditLabReportConfig,
    EditRouteSessionConfig,
    EditWashFacilityConfig,
    EditLabProviderConfig,
    EditDropoffSampleConfig,
    EditSplitProducerConfig,
    EditHaulingContractConfig,
    EditSupplementalHaulingContractConfig,
    EditPaymentStatusConfig,
    EditEffectiveHaulerRateConfig,
    EditHaulerRateConfig,
    EditAdhocPayConfig,
];
