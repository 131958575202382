import { useBasisTheory } from '@basis-theory/basis-theory-react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUserRegion } from '../../../utils';
import { taxCodes } from '../../constants/selects/SensitiveInfoDialogSelects';
import BasisTheoryTextElement from '../../custom-components/form-components/BasisTheoryTextElement';
import ReactHookFormSearchableSelect from '../../custom-components/form-components/ReactHookFormSearchableSelect';
import { addRevealToken } from '../../repository';
import { buildExpressQuery } from '../../repository/queries/builders';
import { createEmployeeIdToken, createSocialSecurityToken } from '../../repository/utils';
import { showMessage } from '../../store/actions';

function PaperComponent(props) {
    return <Paper {...props} />;
}

const ssnMask = [/\d/u, /\d/u, /\d/u, '-', /\d/u, /\d/u, '-', /\d/u, /\d/u, /\d/u, /\d/u];
const einMask = [/\d/u, /\d/u, '-', /\d/u, /\d/u, /\d/u, /\d/u, /\d/u, /\d/u, /\d/u];

const ErrorLabel = ({ error }) =>
    error ? (
        <Grid item xs={12} style={{ paddingTop: 0, marginTop: -4 }}>
            <Typography color="error">{error}</Typography>
        </Grid>
    ) : (
        <></>
    );

export default function TaxSensitiveInfoDialog({ title, dataType, data, forceFetch }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [revealLoading, setRevealLoading] = useState(false);
    const [ssnChange, setSSNChange] = useState(false);
    const [einChange, setEINChange] = useState(false);

    const dispatch = useDispatch();
    const { bt } = useBasisTheory();
    const region = getUserRegion();
    const [error, setError] = useState({});
    const ssnRef = useRef(null);
    const einRef = useRef(null);

    const getDefaultTaxIdType = () => {
        if (data.tax?.length > 0) {
            return taxCodes.filter((taxCode) => taxCode.value === data.tax[0].type)[0];
        }
        return taxCodes[0];
    };

    const getDefaultSSNAndEID = () => {
        const ssnNumberArr = data?.tax?.filter((tax) => tax.type === 'social_security_number');
        const employeeIdNumberArr = data?.tax?.filter((tax) => tax.type === 'employer_id_number');
        return {
            ssn: ssnNumberArr?.length > 0 ? ssnNumberArr[0].masked_data || '' : '',
            ein: employeeIdNumberArr?.length > 0 ? employeeIdNumberArr[0].masked_data || '' : '',
        };
    };

    const defaultValues = useMemo(
        () => ({
            tax_id_type: getDefaultTaxIdType(),
            ...getDefaultSSNAndEID(),
        }),
        [data]
    );

    const reactHookFormMethods = useForm({
        mode: 'onTouched',
        defaultValues: {
            tax_id_type: defaultValues.tax_id_type,
        },
    });

    const { handleSubmit, errors, watch } = reactHookFormMethods;
    const taxIdType = watch('tax_id_type');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const revealToken = async () => {
        setRevealLoading(true);
        const tokenIds = data?.tax?.filter((taxObj) => taxObj.type === taxIdType.value).map((taxObj) => taxObj.token_id);
        if (tokenIds.length === 0) {
            dispatch(showMessage({ message: 'No sensitive information to reveal' }));
            setRevealLoading(false);
            return;
        }
        const session = await bt.sessions.create();
        try {
            await addRevealToken(session.nonce, tokenIds);

            const tokens = await Promise.all(
                tokenIds.map((tokenId) => {
                    return bt.tokens.retrieve(tokenId, {
                        apiKey: session.sessionKey,
                    });
                })
            );

            if (tokens.length > 0 && tokens[0].data) {
                const tokenData = tokens[0].data;
                if (taxIdType.value === 'social_security_number') {
                    ssnRef.current.setValue(tokenData);
                } else if (taxIdType.value === 'employer_id_number') {
                    einRef.current.setValue(tokenData);
                }
            }
            setRevealLoading(false);
        } catch (err) {
            setRevealLoading(false);
            dispatch(showMessage({ message: 'Failed to reveal sensitive information. Please contact support.' }));
        }
    };

    const handleChange = (event, setChange) => {
        if (event.complete) {
            setChange(true);
        } else {
            setChange(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setError({});
    };

    const setErroMessage = (label, message) => {
        setError((prev) => ({ ...prev, [label]: message }));
    };

    const btErrorMessage = (message) => {
        if (taxIdType.value === 'social_security_number') {
            setErroMessage('socialSecurityNumber', `Social Security Number ${message}`);
        } else {
            setErroMessage('employeeIdNumber', `Employee Id Number ${message}`);
        }
    };

    const onSubmit = async (model) => {
        setError({});
        if (!bt) {
            setErroMessage('form', 'Please wait a moment and try again.  If the problem persists please contact support.');
            return;
        }
        setLoading(true);
        try {
            let tokenData;
            if (taxIdType.value === 'social_security_number') {
                tokenData = await createSocialSecurityToken(bt, ssnRef.current, region);
            } else if (taxIdType.value === 'employer_id_number') {
                tokenData = await createEmployeeIdToken(bt, einRef.current, region);
            }

            const submitModel = {
                producerId: data._id,
                token: tokenData,
            };

            await axios.post(buildExpressQuery('/users/add-token', {}), submitModel);

            dispatch(showMessage({ message: 'Successfully updated sensitive information' }));
            forceFetch(tokenData);
            setOpen(false);
            setLoading(false);
        } catch (err) {
            const btErrors = err.data?.errors;
            if (btErrors) {
                btErrorMessage('is invalid');
            } else {
                btErrorMessage('should be 9 digits');
            }
            setLoading(false);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" className="my-16" aria-label={title} onClick={handleClickOpen}>
                {title}
            </Button>

            <FormProvider {...reactHookFormMethods}>
                <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">{dataType} Sensitive Information</DialogTitle>

                    <form onSubmit={handleSubmit(onSubmit, errors)}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ReactHookFormSearchableSelect name="tax_id_type" label="Tax Id Type" options={taxCodes} required hideCheckbox disableClearable />
                                </Grid>
                                {taxIdType?.value === 'social_security_number' && (
                                    <>
                                        <Grid item xs={12}>
                                            <BasisTheoryTextElement label="Social Security Number" id="socialSecurityNumber" elementRef={ssnRef} placeholder={defaultValues?.ssn} mask={ssnMask} setIsEmpty={() => {}} onChange={(e) => handleChange(e, setSSNChange)} />
                                        </Grid>
                                        <ErrorLabel error={error?.socialSecurityNumber} />
                                    </>
                                )}
                                {taxIdType?.value === 'employer_id_number' && (
                                    <>
                                        <Grid item xs={12}>
                                            <BasisTheoryTextElement label="Employee Id Number" id="employeeIdNumber" elementRef={einRef} mask={einMask} placeholder={defaultValues?.ein} onChange={(e) => handleChange(e, setEINChange)} setIsEmpty={() => {}} />
                                        </Grid>
                                        <ErrorLabel error={error?.employeeIdNumber} />
                                    </>
                                )}
                                <ErrorLabel error={error?.form} />
                            </Grid>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px' }}>
                            <Button onClick={revealToken} variant="outlined" color="secondary" disabled={revealLoading}>
                                Reveal
                                {revealLoading && (
                                    <div className="flex items-center justify-center" style={{ marginLeft: 10 }}>
                                        <CircularProgress size={20} />
                                    </div>
                                )}
                            </Button>
                            <div>
                                <Button onClick={handleClose} color="secondary" style={{ marginRight: 5 }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" disabled={loading || (!ssnChange && !einChange)}>
                                    Update
                                    {loading && (
                                        <div className="flex items-center justify-center" style={{ marginLeft: 10 }}>
                                            <CircularProgress size={20} />
                                        </div>
                                    )}
                                </Button>
                            </div>
                        </DialogActions>
                    </form>
                </Dialog>
            </FormProvider>
        </div>
    );
}
