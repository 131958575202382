import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Button, Grid, Box, Typography, makeStyles, debounce } from '@material-ui/core';
import { showMessage, addSplitProducer, editSplitProducer } from 'app/store/actions';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import moment from 'moment-timezone';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { basis, splitPortion } from '../form-components/SplitProducerSelects';
import ReactHookFormArrayOfObjects from '../form-components/ReactHookFormArrayOfObjects';
import SplitProducerAgreementDetails from '../form-components/SplitProducerAgreementDetails';
import SplitProducerSchema from '../form-schemas/SplitProducerSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { filterProducersByParentAndChild, generateTestId } from '../../../utils';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const SplitProducerForm = ({ editData, producers }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [parentProducers, childProducers] = useMemo(() => filterProducersByParentAndChild(producers), [producers]);

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            parent_producer: editData?.parent_producer,
            child_producer: editData?.child_producer,
            split_producer_agreement_details: editData?.split_producer_agreement_details?.map((producer_agreement) => {
                return {
                    basis: _.find(basis, { value: producer_agreement.basis }),
                    split_portion: _.find(splitPortion, { value: producer_agreement.split_portion }),
                    split_rate: producer_agreement.split_rate,
                };
            }),
            start_date: moment(editData?.start_date),
            end_date: editData?.end_date ? moment(editData.end_date) : undefined,
        },
        resolver: yupResolver(SplitProducerSchema),
    });
    const { handleSubmit, errors, control } = reactHookFormMethods;

    const transformProducerLabel = (option) => {
        if (option.license_number && option.name) {
            return `${option.license_number} - ${option.name}`;
        }
        return '';
    };

    const post = (submitModel) => {
        dispatch(addSplitProducer(submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Split Producer' }));
                history.replace({ pathname: '/split-producers' });
            })
            .catch(() => {
                dispatch(showMessage({ message: 'Could not add Split Producer' }));
            });
    };

    const patch = (submitModel) => {
        const { _id } = editData;
        dispatch(editSplitProducer(submitModel, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Split Producer' }));
                history.replace({ pathname: '/split-producers' });
            })
            .catch(() => {
                dispatch(showMessage({ message: 'Could not edit Split Producer' }));
            });
    };

    const onSubmit = (model) => {
        const submitModel = {
            ...model,
            split_producer_agreement_details: model.split_producer_agreement_details.map((producer_agreement) => {
                return {
                    basis: producer_agreement.basis?.value,
                    split_portion: producer_agreement.split_portion?.value,
                    split_rate: producer_agreement.split_rate,
                };
            }),
            start_date: model.start_date || moment(),
            end_date: model.end_date || null,
        };
        // Express expects object id fields to be _id but it gets changed to id on the frontend
        // eslint-disable-next-line no-underscore-dangle
        submitModel.parent_producer._id = submitModel.parent_producer.id;
        // eslint-disable-next-line no-underscore-dangle
        submitModel.child_producer._id = submitModel.child_producer.id;

        if (editData) {
            patch(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);
    const render = () => {
        return (
            <div className={classes.root}>
                <FormProvider {...reactHookFormMethods}>
                    <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ReactHookFormSearchableSelect label={'Parent Producer'} name={'parent_producer'} options={parentProducers} customRender={transformProducerLabel} required />
                            </Grid>

                            <Grid item xs={6}>
                                <ReactHookFormSearchableSelect label={'Child Producer'} name={'child_producer'} options={childProducers} customRender={transformProducerLabel} required />
                            </Grid>

                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            const startOfMonth = value?.startOf('month');
                                            return <DatePicker data-testid={generateTestId('Start Date', 'date-picker')} fullWidth onChange={onChange} views={['month', 'year']} format="MM/DD/YYYY" value={startOfMonth} label="Start Date" />;
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="end_date"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            const endOfMonth = value?.endOf('month') ?? null;
                                            return <DatePicker data-testid={generateTestId('End Date', 'date-picker')} fullWidth onChange={onChange} views={['month', 'year']} format="MM/DD/YYYY" value={endOfMonth} label="End Date" />;
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        <ReactHookFormArrayOfObjects
                            name="split_producer_agreement_details"
                            title="Agreement Details"
                            objectToAppend={{
                                basis: null,
                                split_portion: null,
                                split_rate: null,
                            }}
                            CustomRender={SplitProducerAgreementDetails}
                            objectToExpand={editData && { basis: editData.basis, split_portion: editData.split_portion, split_rate: editData.split_rate }}
                        />

                        <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                            <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                Save
                            </Button>
                        </Box>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return <>{render()}</>;
};

export default withRouter(SplitProducerForm);
