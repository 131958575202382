import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { getExpressAPIHost, getUserRegion } from '../../utils';
import { getCurrentSession } from '@milkmoovement/common_cognito_package';
import { NotificationContext } from '../NotificationContext';

const useSSE = (type, interval) => {
    const { showSnackbar } = useContext(NotificationContext);
    const url = `${getExpressAPIHost()}/live-events/subscribe/${type}`;
    const [data, setData] = useState();
    const [status, setStatus] = useState('disconnected');
    const eventQueue = useRef([]);
    const retries = useRef(0);
    const isProcessing = useRef(false);

    const eventSourceRef = useRef(null);

    const connect = useCallback(async () => {
        try {
            const session = await getCurrentSession();
            const jwt = session.getIdToken().getJwtToken();
            const eventSource = new EventSourcePolyfill(url, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    customer: getUserRegion(),
                },
            });

            eventSourceRef.current = eventSource;

            eventSource.onopen = () => {
                setStatus('connected');
            };

            const handleEvent = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);
                    if (interval) {
                        eventQueue.current.push(parsedData);
                        processQueue();
                    } else {
                        setData(parsedData);
                    }
                } catch (_err) {
                    showSnackbar({ severity: 'error', message: 'Malformed update received' });
                }
            };

            const processQueue = () => {
                if (isProcessing.current || eventQueue.current.length === 0) {
                    return;
                }

                isProcessing.current = true;

                const parsedData = eventQueue.current.shift();
                setData(parsedData);

                setTimeout(() => {
                    isProcessing.current = false;
                    processQueue();
                }, interval);
            };

            eventSource.onmessage = handleEvent;

            eventSource.onerror = (_err) => {
                setStatus('error');
                eventSource.close();
                eventSourceRef.current = null;
                // TODO: Alert "Reconnecting..."
                if (retries.current === 0) {
                    showSnackbar({ severity: 'error', message: 'Live update connection lost' });
                }
            };

            return eventSource;
        } catch (err) {
            setStatus('error');
            showSnackbar({ severity: 'error', message: 'Could not connect to live updates' });
            return null;
        }
    }, [url]);

    useEffect(() => {
        connect();

        // Cleanup function to close the connection
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventQueue.current = [];
                isProcessing.current = false;
            }
        };
    }, []);

    useEffect(() => {
        let retryInterval;
        if (status === 'error') {
            // Retry every 5 seconds until connected up to 3 times
            retryInterval = setInterval(() => {
                retries.current += 1;
                if (retries.current < 3) {
                    connect();
                } else {
                    clearInterval(retryInterval);
                }
            }, 5000);
        } else if (retryInterval) {
            retries.current = 0;
            showSnackbar({ severity: 'success', message: 'Live updates restored.' });
            clearInterval(retryInterval);
        }

        return () => {
            if (retryInterval) clearInterval(retryInterval);
        };
    }, [status]);

    // Function to clear the data array
    const clearData = useCallback(() => {
        setData();
    }, []);

    return {
        data,
        status,
        clearData,
    };
};

export default useSSE;
