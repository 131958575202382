import CACIQUE from './cacique-config';
import CDI from './cdi-config';
import LEGACY from './legacy-config';
import MMPA from './mmpa-config';
import UDA from './uda-config';
import PRAIRIE from './prairie-config';
import TCJ from './tcj-config';
import UNC from './unc-config';
import RF from './rf-config';
import NL from './nl-config';
import PEI from './pei-config';
import USDEMO from './us-demo-config';
import DEFAULT from './default-config';
import PSC from './psc-config';
import { getUserPermissions } from './UserPermissions/user-based-permissions';
import { getUser } from '../../../../utils';
import Split from '../../../split';

const Permissions = {
    CACIQUE,
    CDI,
    LEGACY,
    MMPA,
    PRAIRIE,
    'TCJ-LIBERTY': TCJ,
    'TCJ-WHITEEAGLE': TCJ,
    'TCJ-GPDA': TCJ,
    'TCJ-ERIE': TCJ,
    'TCJ-NEBRASKA': TCJ,
    'TCJ-KYTN': TCJ,
    UDA,
    UNC,
    RF,
    NL,
    PEI,
    USDEMO,
    DEMO: USDEMO,
    PSC,
};

const getPermissions = (userRole) => {
    const user = getUser();
    const regionConfig = Permissions[user.region] ?? DEFAULT;
    const roleConfig = regionConfig[userRole];
    const userPermissions = getUserPermissions(userRole);
    const requiredPermissions = (roleConfig.required ?? []).reduce((acc, p) => acc.add(p.value), new Set());

    // Check if the current user has "ManageBankingInfo"
    const hasManageBankingInfo = user.permissions.includes('ManageBankingInfo');

    // Evaluate permissions with splits out of the list
    const evaluatedPermissions = (roleConfig.permissions ?? [])
        .map((group) => {
            let filteredPermissions = (group.permissions ?? []).filter((permission) => {
                // Remove "ManageBankingInfo" if user does NOT have it
                if (permission.value === 'ManageBankingInfo' && !hasManageBankingInfo) {
                    return false;
                }
                return !permission.split || Split.getBooleanTreatment(permission.split);
            });

            return filteredPermissions.length > 0 ? { ...group, permissions: filteredPermissions } : null;
        })
        .filter((group) => group !== null);

    return {
        permissionGroups: [...evaluatedPermissions, ...userPermissions],
        requiredPermissions,
        // force-wrap
    };
};

export default getPermissions;
