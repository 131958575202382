import { MOBILE_RECEIVER_UI_REGIONS } from '../../../constants/region-utils';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        return MOBILE_RECEIVER_UI_REGIONS.includes(attributes.region) && attributes.email === 'developer@milkmoovement.com' ? 'on' : 'off';
    },
});

const demo = new Environment({
    name: stages.DEMO,
    evaluationFn: (attributes) => {
        return 'on';
    },
});

const staging = new Environment({
    ...prod,
    name: stages.STAGING,
});

const local = new Environment({
    ...prod,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
});

const can_manage_mobile_app_processor_workflow = new MockSplit({
    name: 'can_manage_mobile_app_processor_workflow',
    environments: [local, dev, staging, demo, prod],
});

export default can_manage_mobile_app_processor_workflow;
