import { FuseLoadable } from '@fuse';

const PriceManagement = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/price-management',
            component: FuseLoadable({
                loader: () => {
                    return import('./PriceManagement');
                },
            }),
        },
    ],
};

const RawMilkPricing = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/price-management/raw-milk-invoicing',
            component: FuseLoadable({
                loader: () => {
                    return import('./raw-milk/RawMilkPricing');
                },
            }),
        },
    ],
};

const ProcessedFluidsPricing = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/price-management/processed-fluids-invoicing',
            component: FuseLoadable({
                loader: () => {
                    return import('./processed-fluids/ProcessedFluidsPricing');
                },
            }),
        },
    ],
};

export default [PriceManagement, RawMilkPricing, ProcessedFluidsPricing];
