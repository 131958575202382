import { useQuery } from '@tanstack/react-query';
import useExpress from '../../../../useExpress';

const getTrailerEvents = (routeSessionId) => {
    const params = {
        populate: {
            created_by: ['name', 'full_name'],
            'geofence_id.user_id': ['name', 'full_name'],
        },
    };

    if (routeSessionId) {
        params.filter = {
            route_session_id: { $eq: routeSessionId },
        };
    }

    const endpoint = '/trailer-event';
    return useExpress({ endpoint, params });
};

export const useGetTrailerEvents = ({ routeSessionId, enabled = true } = {}) => {
    const queryFn = getTrailerEvents(routeSessionId);
    const queryKey = ['trailer-event', routeSessionId];
    return useQuery({ queryKey, queryFn, enabled });
};

const getPlaybackDetails = (routeSessionId, isProcessedFluids) => {
    const path = `${isProcessedFluids ? '/processed-fluid' : ''}/route-sessions`;
    const endpoint = `${path}/playback-details/${routeSessionId}`;
    return useExpress({ endpoint });
};

export const useGetPlaybackDetails = ({ routeSessionId, isProcessedFluids, enabled = true } = {}) => {
    const queryFn = getPlaybackDetails(routeSessionId, isProcessedFluids);
    const queryKey = ['playback-details', routeSessionId];
    return useQuery({ queryKey, queryFn, enabled });
};
